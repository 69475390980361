import React, { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import User from "store/user";
import { Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useApolloClient, gql } from "@apollo/client";

import jwt_decode from "jwt-decode";

function PrivateRoute({ component: Component }) {
	const client = useApolloClient();

	const [state, setState] = useState({
		status: "loading",
		path: null,
	});

	const logout = () => {
		User.logout().then(() => {
			setState({ loginRedirect: true });
		});
	};

	const checkUser = async () => {
		let data = await localStorage.getItem("user");
		if (!data) return setState({ status: "redirect", path: "/login" });

		data = JSON.parse(data);
		if (!data.refreshToken || !data.token || !data.refreshToken) {
			await localStorage.removeItem("user");
			return setState({ status: "redirect", path: "/login" });
		}

		User.data = data;

		setState({ status: "success" });

		// User.check(client).then(
		// 	(result) => {
		// 		setState({ status: "success" });

		// 		// // Watch for auth permissions
		// 		// interval = setInterval(() => {
		// 		//     User
		// 		//     .check(client)
		// 		//     .then(async (result) => {
		// 		//         if(!result) {
		// 		//           await localStorage.removeItem('user');
		// 		//           setState({status: 'redirect', path: '/login'});
		// 		//         }
		// 		//     })
		// 		//     .catch(async (error) => {
		// 		// await localStorage.removeItem('user');
		// 		// setState({status: 'redirect', path: '/login'});
		// 		//     });

		// 		// }, 30000);
		// 	},
		// 	async (e) => {

		// 		console.log(e);

		// 		//await localStorage.removeItem("user");

		// 		if (e === "server_error") {
		// 			setState({ status: "server_error" });
		// 		} else {
		// 			setState({ status: "redirect", path: "/login" });
		// 		}
		// 	}
		// );
	};

	useEffect(() => {
		let interval;
		checkUser();
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<Route
			render={(props) => {
				if (state.status === "server_error") {
					return (
						<div>
							<Result
								status="500"
								title="Ops"
								subTitle="Algo deu erro. Por favor, tente novamente mais tarde."
							/>
						</div>
					);
				}

				if (state.status === "redirect") {
					return (
						<Redirect
							to={{
								pathname: state.path,
								state: { from: props.location },
							}}
						/>
					);
				}

				if (state.status === "loading") {
					return (
						<div className="loading-view">
							<div className="spinner-wrapper">
								<LoadingOutlined />
							</div>
						</div>
					);
				}

				return <Component {...props} />;
			}}
		/>
	);
}

export default PrivateRoute;
