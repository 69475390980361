import { Select, Spin } from "antd";
import { useLazyQuery } from "@apollo/client";
import debounce from "functions/debounce";
import { gql } from "@apollo/client";

const { Option } = Select;

const AUTOCOMPLETE_QUERY = gql`
	query BusinessAutocomplete($term: String!, $type: String!) {
		BusinessAutocomplete(term: $term, type: $type) {
			_id,
			company_name
		}
	}
`;

const BusinessSearch = ({
	onChange,
	defaultValue,
	disabled,
	additionalProps,
}) => {
	const [search, { loading, data: response }] =
		useLazyQuery(AUTOCOMPLETE_QUERY);

	const onSearch = debounce((e) => searchAction(e), 500);

	const searchAction = async (term) => {
		await search({
			variables: {
				term,
        type: "company_name"
			},
		});
	};

	return (
		<Select
			disabled={disabled}
			showSearch
			showArrow={false}
			filterOption={false}
			onSearch={onSearch}
			notFoundContent={loading ? <Spin size="small" /> : null}
			onChange={onChange}
			placeholder="Empresas"
			defaultValue={defaultValue}
			{...additionalProps}
		>
			{response?.BusinessAutocomplete?.length &&
				response.BusinessAutocomplete.map((item) => {
					return (
						<Option key={item._id} value={item._id}>
							{item.company_name}
						</Option>
					);
				})}
		</Select>
	);
};

export default BusinessSearch;
