import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Typography,
	Button,
	Form,
	Input,
	Select,
	DatePicker,
	notification,
	Divider,
	Tag,
	Checkbox,
} from "antd";
import Estados from "functions/states";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import { useDebounce } from "@react-hook/debounce";

import { useImmer } from "use-immer";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const [state, setState] = useImmer({
		address: {},
	});
	const { id } = props.match.params;
	const [load, { data, loading, error, refetch }] = useLazyQuery(
		Queries.PARTNER_GET,
		{
			fetchPolicy: "no-cache",
		}
	);
	const [cepLoading, setCepLoading] = useState(false);
	const [partnerId, setPartnerId] = useState(null);
	const [cep, setCep] = useDebounce(null, 500);
	const [saveCompany, { loading: loadingSave }] = useMutation(
		Queries.PARTNER_SAVE
	);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		if (id && id !== "new") {
			load({
				variables: {
					id,
				},
			});
		}
	}, []);

	useEffect(() => {
		if (data?.Partner) {
			const { user_id, ...payloadData } = data.Partner;

			setPartnerId(user_id);

			let payload = { ...payloadData };

			if (payload.date_start) {
				payload.date_start = moment(payload.date_start);
			}
			if (payload.date_end) {
				payload.date_end = moment(payload.date_end);
			}

			if (!payload.address) payload.address = {};

			setState(payload);
		}
	}, [data]);

	useEffect(() => {
		async function getCep(cep) {
			let code = cep.replace(/\D/g, "");

			if (code.length === `00000000`.length) {
				setCepLoading(true);
				let response = await axios.get(
					`https://viacep.com.br/ws/${code}/json/`
				);
				setCepLoading(false);

				if (response?.data) {
					setState((draft) => {
						draft.address = {
							...draft.address,
							street: response.data.logradouro,
							neighborhood: response.data.bairro,
							city: response.data.localidade,
							state: response.data.uf,
						};
					});
				} else {
					return ErrorDialog("Não foi possível carregar o CEP");
				}
			}
		}
		if (cep) getCep(cep);
	}, [cep]);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		console.log(state);

		if (!state.company_name)
			return ErrorDialog("Por favor, informe uma razão social válida.");
		if (!state.plan_id)
			return ErrorDialog("Por favor, informe um plano válido.");
		if (state.status === null)
			return ErrorDialog("Por favor, informe um status válido.");

		if (!state.email) return ErrorDialog("Por favor, informe um email válido.");
		if (!state.partner_type)
			return ErrorDialog("Por favor, informe o tipo de parceiro.");

		if (!state.address.street)
			return ErrorDialog("Por favor, insira um endereço válido.");
		if (!state.address.number)
			return ErrorDialog("Por favor, insira um número válido.");
		if (!state.address.city)
			return ErrorDialog("Por favor, insira uma cidade válida.");
		if (!state.address.state)
			return ErrorDialog("Por favor, selecione um estado.");

		let input = { ...state };

		console.log(input);

		if (input.date_start) {
			input.date_start = input.date_start.toISOString();
		}
		if (input.date_end) {
			input.date_end = input.date_end.toISOString();
		}

		try {
			let res = await saveCompany({
				variables: { input },
			});

			const { _id: partnerId } = res.data.Partner;
			const payload = res.data.Partner;

			if (payload.date_start) {
				payload.date_start = moment(payload.date_start);
			}
			if (payload.date_end) {
				payload.date_end = moment(payload.date_end);
			}

			console.log(payload);

			setState(payload);
			if (id === "new") {
				notification.success({
					message: "Parceiro salvo com sucesso!",
				});
				props.history.push(`/partner/${partnerId}`);
			} else {
				notification.success({
					message: "Parceiro salvo com sucesso!",
				});
			}
		} catch (e) {
			ErrorDialog("Erro ao salvar o parceiro. Por favor, tente novamente.");
		}
	};

	if (loading) {
		return (
			<Layout className={"page-wrapper"}>
				<Content className="site-layout-background">
					<div className="page-title">
						<Title>{"Editar Parceiro"}</Title>
					</div>
					<Loading />
				</Content>
			</Layout>
		);
	}

	if (error) {
		return (
			<Layout className={"page-wrapper"}>
				<Content className="site-layout-background">
					<div className="page-title">
						<Title>{"Editar Parceiro"}</Title>
					</div>

					<Error
						refetch={() => {
							load({
								variables: { id },
							});
						}}
					/>
				</Content>
			</Layout>
		);
	}

	console.log(state);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>{id === "new" ? "Novo Parceiro" : "Editar Parceiro"}</Title>
				</div>

				<div style={{ marginBottom: 20 }}>
					ID do Parceiro: <br /> <Tag>{partnerId}</Tag>
				</div>

				<Form layout="vertical">
					<Title level={3}>Informações do Usuário</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="E-mail">
								<Input
									value={state.email || null}
									onChange={(e) => {
										setState((draft) => {
											draft.email = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Senha">
								<Input
									type={"password"}
									value={state.password || null}
									onChange={(e) => {
										setState((draft) => {
											draft.password = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>

					<br />

					<Title level={3}>Informações Básicas</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Razão Social">
								<Input
									value={state.company_name || null}
									onChange={(e) => {
										setState((draft) => {
											draft.company_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Nome Fantasia">
								<Input
									value={state.trade_name || null}
									onChange={(e) => {
										setState((draft) => {
											draft.trade_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="CNPJ">
								<MaskedInput
									mask="11.111.111/1111-11"
									value={state.cnpj || null}
									onChange={(e) => {
										setState((draft) => {
											draft.cnpj = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Telefone">
								<MaskedInput
									mask="(11) 1111-1111"
									value={state.phone || null}
									onChange={(e) => {
										setState((draft) => {
											draft.phone = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Celular">
								<MaskedInput
									mask="(11) 11111-1111"
									value={state.mobile || null}
									onChange={(e) => {
										setState((draft) => {
											draft.mobile = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item label="Nome do Contato">
								<Input
									value={state.contact_name || null}
									onChange={(e) => {
										setState((state) => {
											state.contact_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={12}>
							<Row gutter={30}>
								<Col span={12}>
									<Form.Item label="Data de Início">
										<DatePicker
											value={state.date_start || null}
											onChange={(d) => {
												setState((draft) => {
													draft.date_start = d;
												});
											}}
											format={"DD/MM/YYYY"}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Data Final">
										<DatePicker
											value={state.date_end || null}
											onChange={(d) => {
												setState((draft) => {
													draft.date_end = d;
												});
											}}
											format={"DD/MM/YYYY"}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Col>

						<Col span={12}>
							<Row gutter={30}>
								<Col span={12}>
									<Form.Item label="Plano">
										<Select
											value={state.plan_id || null}
											onChange={(value) => {
												setState((draft) => {
													draft.plan_id = value;
												});
											}}
										>
											<Select.Option value={1}>Mensal</Select.Option>
											<Select.Option value={2}>Pré-pago</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Status">
										<Select
											value={state.status}
											onChange={(value) => {
												console.log(value);
												setState((draft) => {
													draft.status = value;
												});
											}}
										>
											<Select.Option value={0}>Inativo</Select.Option>
											<Select.Option value={1}>Ativo</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Tipo de Parceiro">
										<Select
											value={state.partner_type}
											onChange={(value) => {
												console.log(value);
												setState((draft) => {
													draft.partner_type = value;
												});
											}}
										>
											<Select.Option value={"monthly"}>
												Mensalista
											</Select.Option>
											<Select.Option value={"pre_paid"}>Pré-pago</Select.Option>
											<Select.Option value={"management"}>Gestão</Select.Option>
											<Select.Option value={"white_label"}>
												White Label
											</Select.Option>
											<Select.Option value={"white_label_full"}>
												White Label Full
											</Select.Option>
											<Select.Option value={"white_label_light"}>
												White Label Light
											</Select.Option>
											<Select.Option value={"invoices"}>
												Notas Fiscais
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Extra">
										<Checkbox
											checked={state.ancord}
											onChange={(e) => {
												setState((draft) => {
													draft.ancord = e.target.checked;
												});
											}}
										>
											ANCORD
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
					<br />

					<Title level={3}>Endereço</Title>

					<Row gutter={20}>
						<Col span={6}>
							<Form.Item label="CEP">
								<MaskedInput
									mask={"11111-111"}
									value={state.address?.zip_code || null}
									onChange={(e) => {
										const { value } = e.target;
										setCep(value);
										setState((draft) => {
											draft.address.zip_code = value;
										});
									}}
									loading={cepLoading}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item label="Endereço">
								<Input
									value={state.address?.street || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.address.street = value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Número">
								<Input
									value={state.address?.number || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.address.number = value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Cidade">
								<Input
									value={state.address?.city || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.address.city = value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Bairro">
								<Input
									value={state.address?.neighborhood || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.address.neighborhood = value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Complemento">
								<Input
									value={state.address?.complement || null}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.address.complement = value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Estado">
								<Select
									value={state.address?.state || null}
									onChange={(e) => {
										setState((draft) => {
											draft.address.state = e;
										});
									}}
								>
									{Estados("all").map((item, index) => {
										return (
											<Select.Option key={`state_${index}`} value={item.value}>
												{item.text}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Divider />

					<div>
						<Button
							onClick={save}
							type="primary"
							loading={loadingSave}
							style={{
								marginTop: 20,
								float: "right",
							}}
						>
							Salvar
						</Button>
					</div>
				</Form>
			</Content>
		</Layout>
	);
}

export default User;
