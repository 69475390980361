import React, { useState, useEffect, useRef } from 'react';
import * as Queries from './Queries';
import { useQuery } from "@apollo/client";
import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';
import MaskPrice from 'functions/mask/price';
import Mask from 'functions/mask';
import { Col, Row, Layout, Table, Typography, Button, Form, Input, DatePicker, Select, notification } from 'antd';
import { SearchOutlined, FileOutlined } from '@ant-design/icons';
import { DownloadOutlined, ReloadOutlined, FilePdfOutlined, BarcodeOutlined } from '@ant-design/icons';

import moment from 'moment';
import MaskedInput from "antd-mask-input";
import Swal from 'sweetalert2';

const { Content } = Layout;
const { Title } = Typography;


const now = moment();

function DAS() {


	const [startFilters] = useState({
		period: moment(),
		term: null
	});
	const [filters, setFilters] = useState({
		period: moment(),
		term: null
	});

	const { loading, data, error, refetch } = useQuery(Queries.DAS, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
		variables: {
			period: startFilters.period,
			term: startFilters.term
		}
	});

	const copyCode = (code) => {
		navigator.clipboard.writeText(code);
		notification.success({
			message: 'Código copiado',
			description: 'O número do código de barras foi copiado para sua área de tranferência.'
		})
	}

	const convertIntToCurrency = (val) => {
		if (!val) return '--';
		let cents = val.toString().slice(-2);
		let total = val.toString().slice(0, val.toString().length - 2);
		let amount = parseFloat(`${total}.${cents}`);
		return MaskPrice(amount);
	}

	const columns = [
		{
			title: 'MEI',
			dataIndex: 'business_name',
			key: 'business_name',
			render: (value) => {
				return value
			}
		},
		{
			title: 'CNPJ',
			dataIndex: 'cnpj',
			key: 'cnpj',
			render: (value) => {
				return Mask(value, "99.999.999/9999-99")
			}
		},
		{
			title: 'Período',
			dataIndex: 'referenceDate',
			key: 'referenceDate'
		},
		{
			title: 'Multa',
			dataIndex: 'fine',
			key: 'fine',
			render: (row) => {
				return convertIntToCurrency(row) || '--'
			}
		},
		{
			title: 'Juros',
			dataIndex: 'fees',
			key: 'fees',
			render: (fees) => {
				return convertIntToCurrency(fees) || '--'
			}
		},
		{
			title: 'Total',
			dataIndex: 'total',
			key: 'total',
			render: (value) => {
				return convertIntToCurrency(value) || "-"
			}
		},
		{
			title: 'Vencimento',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (row) => {
				return row ? moment(row).add(3, 'hours').format("DD/MM/YYYY") : '--'
			}
		},
		{
			title: 'Pagamento',
			dataIndex: 'finalPaymentDate',
			key: 'finalPaymentDate',
			render: (value, row) => {
				if (row?.paymentStatus === 'paid' && row?.finalPaymentDate) {
					return value;
				}
				return '--';
			}
		},
		{
			title: 'DAS',
			dataIndex: 'file',
			key: 'file',
			width: 150,
			render: (val, row) => {

				return (
					<div>
						{
							(row.paymentStatus !== 'paid') ? (
								<>
									<div style={{ flexDirection: 'row', display: 'flex' }}>
										<Button type="default" icon={<FilePdfOutlined />} size={20} onClick={() => {
											window.open(`${process.env.REACT_APP_PUBLIC}/das/${window.btoa(row.guid)}`, '_blank');
										}} style={{ marginLeft: 10 }}>Gerar</Button>
										<Button type="default" icon={<BarcodeOutlined />} size={20} onClick={() => {
											copyCode(row.digitableLine)
										}} style={{ marginLeft: 10 }}>Copiar Código</Button>
									</div>
								</>
							) : '--'
						}
					</div>
				)
			}
		},
	];


	const filterAction = () => {

		if (!filters.period) return Swal.fire({
			title: "Erro",
			text: 'Por favor, informe um período.',
			type: "error",
			confirmButtonText: "OK"
		});

		let obj = {
			period: filters.period.toISOString()
		};

		if (filters.term) {
			if (filters.term.replace(/[^0-9]/g, '').length < 14) {
				return Swal.fire({
					title: "Erro",
					text: 'Por favor, insira um CNPJ válido.',
					type: "error",
					confirmButtonText: "OK"
				});
			} else {
				obj.term = filters.term;
			}
		} else {
			obj.term = null;
		}
		refetch(obj);

	}


	console.log(data);

	return (
		<Layout className={'page-wrapper'}>
			<Content className="site-layout-background">

				<div className="page-title">
					<Title>DAS</Title>
				</div>

				<div style={{ marginBottom: '30px' }}>
					<Form

					>

						<Row gutter={20}>
							<Col flex="auto">
								<Form.Item
									name="month"
								>
									<DatePicker
										onChange={(e) => {
											setFilters(filters => {
												return {
													...filters,
													period: e
												}
											})
										}}
										defaultValue={filters.period}
										placeholder={'Selecione um mês'}
										picker="month"
										style={{ width: '100%' }}
										format={'MMMM/YYYY'}
										disabledDate={(current) => {
											const start = moment('2018-01-01', 'YYYY-MM-DD');
											return current < start || current > moment().add(1, 'M');;
										}}
									/>
								</Form.Item>
							</Col>
							<Col flex="auto">
								<Form.Item
									name="year"

								>
									<MaskedInput
										mask={'11.111.111/1111-11'}
										placeholder="Buscar por CNPJ"
										prefix={<SearchOutlined />}
										onChange={(e) => {
											const { value } = e.target;
											setFilters(state => {
												return {
													...state,
													term: value
												}
											})
										}}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Button onClick={filterAction}>Filtrar</Button>
							</Col>
						</Row>







					</Form>
				</div>

				<div className="dashboard">

					{loading ? (
						<Loading />
					) : null}


					{
						(!loading && !error && data) ? (

							<div>
								<Table
									rowKey="_id"
									dataSource={data?.DAS_v3?.length ? data.DAS_v3 : []}
									columns={columns}
								/>

							</div>

						) : null
					}

					{
						(!loading && error) ? (
							<Error
								refetch={() => refetch()}
							/>
						) : null
					}


				</div>
			</Content>
		</Layout>
	);
}

export default DAS;
