import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import {
	Modal,
	Button,
	Col,
	Row,
	Form,
	Select,
	Upload,
	Descriptions,
} from "antd";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import { useImmer } from "use-immer";
import Swal from "sweetalert2";

function ModalView({ visible, refetch, close, data }) {
	const [saveInvoice, { loading }] = useMutation(Queries.INVOICE_SAVE);
	const [state, setState] = useImmer({});
	const [info, setInfo] = useImmer({});
	const onClose = () => {
		setState({});
		setInfo({});
		close();
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	useEffect(() => {
		if (visible) {
			setInfo(data);

			console.log(data);

			setState((d) => {
				if (data.status) {
					d.status = data.status;
				}
				if (data.fileURL) {
					d.fileURL = data.fileURL;
				}
			});
		}
	}, [data, visible]);

	const save = async () => {
		try {
			let input = {
				status: state?.status || "pending",
				file: state?.file || null,
				user: data.user,
				business_id: data.business_id,
			};


			let res = await saveInvoice({
				variables: {
					id: info._id,
					input,
				},
			});

			if (res?.data?.Invoice?._id) {
				close();
				setInfo({});
				setState({});
				refetch();
			} else {
				ErrorDialog("Não foi possível atualizar as informações no momento.");
			}
		} catch (error) {
			console.log(error);
			ErrorDialog(
				error.message || "Não foi possível atualizar as informações no momento."
			);
		}
	};

	return (
		<Modal
			title="Nota Fiscal"
			visible={visible}
			onOk={save}
			width={800}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Descriptions title="Sobre a Nota" bordered size={"small"} column={1}>
				<Descriptions.Item label="Nome da Empresa">
					{info?.business_name || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Tomador">
					{info?.taker || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="CPF/CNPJ">
					{info?.cpfcnpj || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Valor">
					{info?.amount?.toLocaleString("pt-BR", {
						style: "currency",
						currency: "BRL",
					}) || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Descrição">
					{info?.description || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Observações">
					{info?.notes || "--"}
				</Descriptions.Item>
				<Descriptions.Item label="Código de Tributação Nacional">
					{info?.service_code_text || "--"}
				</Descriptions.Item>
				{info?.user_files?.length ? (
					<Descriptions.Item label="Arquivos">
						{info?.user_files.map((item, index) => {
							return (
								<>
									<a href={item.key}>{item.name}</a>
									<br />
								</>
							);
						})}
					</Descriptions.Item>
				) : null}
			</Descriptions>

			<Form layout="vertical" style={{ marginTop: 20 }}>
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label={"Status"}>
							<Select
								value={state?.status || ""}
								onChange={(e) => {
									setState((d) => {
										d.status = e;
									});
								}}
							>
								<Select.Option value="pending">Aguardando</Select.Option>
								<Select.Option value="done">Finalizado</Select.Option>
								<Select.Option value="cancelled">Cancelado</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item label={"Arquivo"}>
							{state.fileURL && (
								<div style={{ marginBottom: 10 }}>
									<Button
										type="primary"
										icon={<FilePdfFilled />}
										onClick={() => {
											window.open(state.fileURL);
										}}
									>
										Ver arquivo
									</Button>
								</div>
							)}

							<Upload
								maxCount={1}
								accept={".pdf,.gif,.jpg,.jpeg,.png"}
								beforeUpload={() => false}
								onChange={(e) => {
									setState((d) => {
										d.file = e.file;
									});
								}}
							>
								<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
							</Upload>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}

export default ModalView;
