import React from "react";
import { Select } from "antd";

export default function BusinessStatus() {
	return [
		{ value: "1", label: "Em negociação" },
		{ value: "2", label: "Documentação Pendente" },
		{ value: "3", label: "Aguardando viabilidade" },
		{ value: "4", label: "Aguardando pagamento de taxas" },
		{ value: "5", label: "Aguardando assinaturas" },
		{ value: "6", label: "Em processo na junta comercial" },
		{ value: "7", label: "CNPJ aberto" },
		{ value: "8", label: "Aguardando Certificado Digital" },
		{ value: "9", label: "Em processo de liberação de Alvará/NF" },
		{ value: "10", label: "Processo concluído" }
	];
}
