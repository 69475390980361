import { Drawer, Radio, Input, Select, Form, Button, DatePicker } from "antd";
import { useLazyQuery, gql } from "@apollo/client";
import { useEffect } from "react";
import { ADMINS } from "scenes/tickets/Queries";

export default function Filters({
	setFilters,
	filters,
	visible,
	close,
	filter,
	refetch
}) {
	const [loadPartners, partners] = useLazyQuery(
		gql`
	query Partners {
	  Partners {
		 _id
		 cnpj
		 company_name
		 trade_name
		 phone
		 email
		 plan_id
		 status
		 balance
	  }
	}
 `,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [loadAdmins, admins] = useLazyQuery(ADMINS, {
		fetchPolicy: "no-cache",
	});

	const [loadCategories, categories] = useLazyQuery(
		gql`
		{
			UAU_Categories {
				name
				_id
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		if (visible) {
			loadPartners();
			loadCategories();
			loadAdmins();
		}
	}, [visible]);

	return (
		<Drawer
			title="Filtros"
			visible={visible}
			width={600}
			onClose={() => {
				close();
			}}
		>
			<Form layout="vertical">
				<Form.Item label={"Status"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.status = e;
							});
						}}
						value={filters?.status}
					>
						<Select.Option value={"negotiating"}>Em negociação </Select.Option>
						<Select.Option value={"contract_sent"}>
							Contrato enviado
						</Select.Option>
						<Select.Option value={"contract_signed"}>
							Contrato assinado
						</Select.Option>
						<Select.Option value={"client_dropped"}>
							Cliente desistiu
						</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Nome do Cliente"}>
					<Input
						value={filters?.client_name}
						onChange={(e) => {
							setFilters((d) => {
								d.client_name = e.target.value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Parceiro"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.partner = e;
							});
						}}
						value={filters.partner}
					>
						{partners?.data?.Partners?.length
							? partners?.data?.Partners.map((item, index) => {
									return (
										<Select.Option value={item._id} key={`partner_${item._id}`}>
											{item.company_name}
										</Select.Option>
									);
							  })
							: null}
					</Select>
				</Form.Item>

				<Form.Item label={"Ramo"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.category = e;
							});
						}}
						value={filters?.category}
					>
						{categories?.data?.UAU_Categories?.length
							? categories?.data?.UAU_Categories.map((item, index) => {
									return (
										<Select.Option value={item._id} key={`cat_${item._id}`}>
											{item.name}
										</Select.Option>
									);
							  })
							: null}
					</Select>
				</Form.Item>

				<Form.Item label={"Nome do contato"}>
					<Input
						value={filters?.contact_name}
						onChange={(e) => {
							setFilters((d) => {
								d.contact_name = e.target.value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Contato"}>
					<Input.Group compact>
						<Form.Item style={{ width: "40%" }}>
							<Select
								style={{ width: "100%" }}
								onChange={(e) => {
									setFilters((d) => {
										if (!d.contact) d.contact = {};
										d.contact.type = e;
									});
								}}
								value={filters.contacts_type}
							>
								<Select.Option value={"E-mail"}>E-mail</Select.Option>
								<Select.Option value={"WhatsApp"}>WhatsApp</Select.Option>
								<Select.Option value={"Telefone"}>Telefone</Select.Option>
								<Select.Option value={"Redes Sociais"}>
									Redes Sociais
								</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item style={{ width: "60%" }}>
							<Input
								value={filters?.contacts_value}
								onChange={(e) => {
									setFilters((d) => {
										if (!d.contact) d.contact = {};
										d.contact.value = e.target.value;
									});
								}}
							/>
						</Form.Item>
					</Input.Group>
				</Form.Item>

				<Form.Item label={"Como chegou até nós"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.reference = e;
							});
						}}
						value={filters?.reference}
					>
						<Select.Option value={"Google"}>Google</Select.Option>
						<Select.Option value={"Instagram"}>Instagram</Select.Option>
						<Select.Option value={"Facebook"}>Facebook</Select.Option>
						<Select.Option value={"Prospeção nossa"}>
							Prospeção nossa
						</Select.Option>
						<Select.Option value={"Outros"}>Outros</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={"Data de Cadastro"}>
					<DatePicker.RangePicker
						onChange={(e) => {
							console.log(e);

							setFilters((d) => {
								d.signup_date = e;
							});
						}}
						format={"DD/MM/YYYY"}
						style={{
							width: "100%",
						}}
						value={filters?.signup_date}
					/>
				</Form.Item>
				<Form.Item label={"SDR"}>
					<Input
						value={filters?.sdr}
						onChange={(e) => {
							setFilters((d) => {
								d.sdr = e.target.value;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label={"Funil"}>
					<Select
						onChange={(e) => {
							setFilters((d) => {
								d.funnil = e;
							});
						}}
						value={filters?.funnil}
					>
						<Select.Option value={"Quente"}>Quente</Select.Option>
						<Select.Option value={"Morno"}>Morno</Select.Option>
						<Select.Option value={"Frio"}>Frio</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label="Closer">
					<Select
						value={filters?.closer ?? null}
						loading={admins?.loading}
						onChange={(e) => {
							setFilters((d) => {
								d.closer = e;
							});
						}}
					>
						{admins?.data &&
							admins?.data?.Admins?.map((item, index) => {
								return (
									<Select.Option key={`admin_id_${index}`} value={item._id}>
										{item.name}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>

				<div>
					<Button type="primary" onClick={filter}>
						Filtrar
					</Button>
					<Button
						style={{ marginLeft: 10 }}
						onClick={() => {
							setFilters({});
							refetch({
								filters: {}
							});
							close();
						}}
					>
						Limpar
					</Button>
				</div>
			</Form>
		</Drawer>
	);
}
