import { gql } from '@apollo/client';

export const DAS = gql`
query DAS_v3($period: String!, $term: String) {
  DAS_v3(period: $period, term: $term) {
    business_name
    business_id
    cnpj
    total
    fees
    fine
    paymentStatus
    dueDate
    referenceDate
    guid
    digitableLine
  }
}
`;


export const DAS_SAVE = gql`
mutation DAS($input: DASInput!) {
  DAS(input: $input) {
    cnpj
    value
    payment_date
    competence
    due_date
    file
  }
}
`;

export const BUSINESS_SEARCH = gql`
query BusinessByCNPJ($cnpj: CNPJ!) {
   BusinessByCNPJ(cnpj: $cnpj) {
    _id
    company_name
    trade_name
  }
}
`;


export const DAS_BATCH = gql`
mutation DAS_Batch($input: DASBatchInput!) {
  DAS_Batch(input: $input) {
    result
    errors
  }
}
`;