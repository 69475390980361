import React, { useState } from "react";
import * as Queries from "./Queries";
import { useMutation, useQuery } from "@apollo/client";
import Swal from "sweetalert2";

import { Col, Row, Layout, Typography, Button, Tabs, Empty, Space } from "antd";
import { useHistory } from "react-router-dom";

import UserInfo from "./components/UserInfo";
import UserDocuments from "./components/UserDocuments";
import Business from "./components/Business";
import Address from "./components/Address";
import Contract from "./components/Contract";
import Documents from "./components/Documents";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import AddBusiness from "./AddBusiness";

import UserUAU from "./UserUAU";
import UAULogo from "assets/images/uau.png";

const { Content } = Layout;
const { Title } = Typography;

function User(props) {
	const history = useHistory();
	const { id } = props.match.params;
	const [removeAction, { loading }] = useMutation(Queries.USER_REMOVE);
	const [addBusinessVisible, setAddBusinessVisible] = useState(false);
	const [uau, setUau] = useState(false);

	const userBusinesses = useQuery(Queries.USER_BUSINESSES, {
		fetchPolicy: "no-cache",
		variables: {
			uid: id,
		},
	});

	const remove = () => {
		console.log(id);

		Swal.fire({
			icon: "warning",
			title: "Remover Usuário",
			text: "Tem certeza que deseja remover este usuário? Esta ação é irreversível.",
			showCancelButton: true,
			confirmButtonText: "Sim",
			denyButtonText: `Cancelar`,
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await removeAction({
					variables: {
						id,
					},
				});

				if (res.data.UserRemove) {
					history.push("/users");
				} else {
					Swal.fire({
						icon: "error",
						title: "Erro",
						text: "Não foi possível remover este usuário.",
					});
				}
			}
		});
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Sobre o usuário</Title>

					<div style={{ display: "flex" }}>
						<Button type="default" size="large" onClick={() => {
							setUau(true)
						}}>
							<img src={UAULogo} style={{marginTop: -3}}/>
						</Button>
						<Button
							type="primary"
							style={{ marginLeft: 10 }}
							onClick={remove}
							danger
							size="large"
						>
							Remover Usuário
						</Button>
					</div>
				</div>

				<AddBusiness
					visible={addBusinessVisible}
					uid={id}
					close={(shouldRefetch) => {
						setAddBusinessVisible(false);
						if (shouldRefetch) {
							userBusinesses.refetch();
						}
					}}
				/>

				<UserInfo id={id} />
				<UserDocuments id={id} />

				<UserUAU
					visible={uau}
					close={() => {
						setUau(false);
					}}
					id={id}
				/>

				{userBusinesses?.loading && <Loading />}
				{userBusinesses?.error && <Error />}

				{!userBusinesses.loading && !userBusinesses.error && (
					<>
						{userBusinesses.data.UserBusinesses?.length ? (
							<>
								<div style={{ marginTop: 60 }}></div>
								<h2>Empresas</h2>
								<Tabs
									type="editable-card"
									onEdit={(e) => {
										setAddBusinessVisible(true);
									}}
									tabBarGutter={10}
								>
									{userBusinesses.data.UserBusinesses.map((item, index) => {
										return (
											<Tabs.TabPane
												tab={item?.company_name || item?.trade_name || "-"}
												key={`business_${item._id}`}
												closable={false}
											>
												<Business
													id={id}
													business_id={item._id}
													updateTop={(state) => {
														userBusinesses.refetch();
													}}
												/>
												<Address id={id} business_id={item._id} />
												<Documents id={id} business_id={item._id} />
												<Contract id={id} business_id={item._id} />
											</Tabs.TabPane>
										);
									})}
								</Tabs>
							</>
						) : (
							<Empty description={"Nenhuma empresa encontrada"} />
						)}
					</>
				)}
			</Content>
		</Layout>
	);
}

export default User;
