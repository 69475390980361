import { gql } from '@apollo/client';

export const PARTNERS = gql`
  query Partners {
    Partners {
      _id
      cnpj
      company_name
      trade_name
      phone
      email
      plan_id
      status
      balance
    }
  }
`;

export const PARTNER_GET = gql`
query Partner($id: String) {
  Partner(id: $id) {
    _id
    cnpj
    company_name
    trade_name
    phone
    mobile
    email
    date_start
    date_end
    contact_name
    plan_id
    partner_type
    status
    user_id
    ancord
    address {
      street
      zip_code
      number
      complement
      neighborhood
      city
      state
      municipal_code
    }
  }
}
`;

export const PARTNER_SAVE = gql`
  mutation Partner($input: PartnerInput) {
    Partner(input: $input) {
      _id
      cnpj
      company_name
      trade_name
      phone
      mobile
      email
      date_start
      date_end
      contact_name
      plan_id
      partner_type
      status
      address {
        street
        zip_code
        number
        complement
        neighborhood
        city
        state
        municipal_code
      }
    }
  }
`;


export const PARTNER_REMOVE = gql`
mutation PartnerRemove($id: String!) {
  PartnerRemove(id: $id)
}
`;