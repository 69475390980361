import React, { useEffect, useState } from "react";

import { Menu, Layout, Badge } from "antd";
import {
	AppstoreOutlined,
	WalletOutlined,
	FileOutlined,
	UserOutlined,
	MessageOutlined,
	StarOutlined,
	ShoppingOutlined,
	NotificationOutlined,
	CheckCircleOutlined,
	RocketOutlined,
} from "@ant-design/icons";

import { gql, useQuery } from "@apollo/client";
import { INVOICES } from "scenes/invoices/Queries";

import CertificatePurchase from "components/CertificatePurchase";

// import Logo from "assets/images/login-logo.png";
const { SubMenu } = Menu;
const { Sider } = Layout;

function View(props) {


	
	const [keys, setKeys] = useState(["/"]);
	const { data, refetch } = useQuery(gql`
		{
			AdminBadges {
				invoices
				tickets
			}
		}
	`, {
		fetchPolicy: "no-cache"
	});

	console.log(data);

	window.refetchInvoicesBadge = refetch;

	const handleClick = (e) => {
		props.history.push(e.key);
	};

	const setActiveRoute = () => {
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	};

	useEffect(() => {
		props.history.listen(() => {
			setActiveRoute();
		});

		const { pathname } = props.history.location;

		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	}, []);

	return (
		<Sider width={280} className={"sider"}>
			<div className="logo-wrapper">
				<img
					src={
						require(`themes/${process.env.REACT_APP_THEME}/images/login-logo.png`)
							.default
					}
					alt=""
				/>
			</div>

			<Menu
				onClick={handleClick}
				style={{ width: 280 }}
				defaultSelectedKeys={keys}
				selectedKeys={keys}
				mode="inline"
			>
				<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
					Dashboard
				</Menu.Item>

				<Menu.Item icon={<ShoppingOutlined />} key={"/services"}>
					Serviços
				</Menu.Item>

				<Menu.Item icon={<MessageOutlined />} key={"/tickets"}>
					Tickets
					<Badge count={data?.AdminBadges?.tickets} style={{ marginLeft: 10 }} />
				</Menu.Item>

				<Menu.Item icon={<UserOutlined />} key={"/users"}>
					PJ
				</Menu.Item>

				<Menu.Item icon={<CheckCircleOutlined />} key={"/partners"}>
					Parceiros
				</Menu.Item>

				<Menu.Item icon={<FileOutlined />} key={"/invoices"}>
					Notas Fiscais
					<Badge count={data?.AdminBadges?.invoices} style={{ marginLeft: 10 }} />
				</Menu.Item>

				<Menu.Item icon={<StarOutlined />} key={"/admins"}>
					Administradores
				</Menu.Item>

				<Menu.Item icon={<NotificationOutlined />} key={"/refers"}>
					Clube UAU OpenYOU
				</Menu.Item>

				<Menu.Item icon={<RocketOutlined />} key={"/uau"}>
					UAU CRM Prospecção
				</Menu.Item>
			</Menu>

			<CertificatePurchase/>

		</Sider>
	);
}

export default View;
