import React, { useState, useEffect } from "react";
import {
	Modal,
	Input,
	Form,
	Descriptions,
	Badge,
	Spin,
	List,
	Divider,
	Collapse,
} from "antd";
import { useMutation, gql, useApolloClient } from "@apollo/client";
import * as Queries from "./Queries";
import dayjs from "dayjs";
import { useImmer } from "use-immer";

const badgeColors = {
	0: "#f2423a",
	1: "#1494fc",
	2: "#fe961e",
	3: "#50ad54",
	4: "#9e9e9e",
};

const Observations = ({ visible, setModal, data, refetch }) => {
	const client = useApolloClient();
	const [socialContract, setSocialContract] = useImmer(null);
	const [loading, setLoading] = useImmer(true);
	const [state, setState] = useImmer(null);

	useEffect(() => {
		if (visible) {
			client
				.query({
					query: gql`
          query TicketInformation($id: String!) {
            TicketInformation(id: $id) {
              name
              price
              description
              resolution_date
              status
              user {
                _id
              }
              partner {
                company_name,
                _id
              }
              service {
                _id
                name
              }
              admin_id
              createdAt
              observation
              service_type
              files {
                filename
                url
              }
            }
          }
        `,
					variables: {
						id: data._id,
					},
					fetchPolicy: "no-cache",
				})
				.then((response) => {
					setState(response.data.TicketInformation);

					if (data?.service_type === "SocialContractChange") {
						client
							.query({
								query: Queries.SOCIAL_CONTRACT,
								variables: {
									id: data._id,
								},
								fetchPolicy: "no-cache",
							})
							.then((res) => {
								setLoading(false);
								setSocialContract(res.data.Ticket_SocialContract);
							});
					} else {
						setLoading(false);
					}
				});
		}

		// SOCIAL CONTRACT

		if (!visible) {
			setSocialContract(null);
			setState({});
		}
	}, [data, visible]);

	const renderAddress = (obj) => {
		return (
			<span>
				{obj?.street}, {obj?.number}{" "}
				{obj.complement ? " - " + obj.complement : ""}
				<br />
				{obj.neighborhood} - {obj.city}/{obj.state}
				<br />
				{obj.zip_code}
			</span>
		);
	};

	return (
		<Modal
			title="Observações"
			visible={visible}
			width={800}
			onOk={() => {
				//save();
				setModal((modal) => {
					return {
						visible: false,
					};
				});
			}}
			//loading={loadingSave}
			onCancel={() => {
				setModal((modal) => {
					return {
						visible: false,
					};
				});
			}}
		>
			{loading && (
				<div style={{ textAlign: "center", margin: "20px 0" }}>
					<Spin />
				</div>
			)}

			{state ? (
				<>
					<Descriptions bordered>
						<Descriptions.Item span={3} label="ID do Ticket">
							<code>{state?._id || "-"}</code>
						</Descriptions.Item>
						<Descriptions.Item span={3} label="Serviço">
							<Badge color={badgeColors[state.status]} /> {state?.name || "-"}
						</Descriptions.Item>
						<Descriptions.Item span={3} label="Criado em">
							{state?.createdAt
								? dayjs(state.createdAt).format("DD/MM/YYYY HH:mm:ss")
								: "-"}
						</Descriptions.Item>
						<Descriptions.Item span={3} label="Descrição">
							{state?.descricao || "-"}
						</Descriptions.Item>
						<Descriptions.Item span={3} label="Observações">
							{state?.observation || "-"}
						</Descriptions.Item>
						{state?.files?.length ? (
							<Descriptions.Item span={3} label="Arquivos">
								<List
									dataSource={state.files}
									renderItem={(item) => (
										<List.Item>
											<a href={item.url} target="_blank">
												{item.filename}
											</a>
										</List.Item>
									)}
								/>
							</Descriptions.Item>
						) : null}
					</Descriptions>

					{socialContract ? (
						<div style={{ margin: "20px 0" }}>
							<Divider />

							<h2>Dados de Alteração de Contrato Social</h2>

							{socialContract?.address ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="Endereço">
										{renderAddress(socialContract.address)}
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.email ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="E-mail">
										{socialContract.email}
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.phone ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="Telefone">
										{socialContract.phone}
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.social_capital ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="Capital Social">
										{socialContract.social_capital.toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})}
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.baixa_cnpj === "yes" ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="Baixa CNPJ">
										Sim
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.partners_exclude?.length ? (
								<Descriptions
									bordered
									layout="vertical"
									style={{ marginBottom: 20 }}
								>
									<Descriptions.Item span={3} label="Sócios para Exclusão">
										{socialContract?.partners_exclude.map((item) => {
											return (
												<>
													<span>{item}</span>
													<br />
												</>
											);
										})}
									</Descriptions.Item>
								</Descriptions>
							) : null}

							{socialContract?.partners?.length ? (
								<>
									<h3>Sócios para Inclusão</h3>
									<Collapse>
										{socialContract?.partners.map((partner) => {
											return (
												<>
													<Collapse.Panel header={partner.name}>
														<Descriptions bordered layout="vertical">
															<Descriptions.Item span={3} label="Nome">
																{partner.name}
															</Descriptions.Item>
															{partner?.civil_status ? (
																<Descriptions.Item label="Estado Civil">
																	{partner.civil_status}
																</Descriptions.Item>
															) : null}
															<Descriptions.Item label="CPF">
																{partner.cpf}
															</Descriptions.Item>
															<Descriptions.Item label="Sócio Administrador">
																{partner.managing_partner === "yes"
																	? "Sim"
																	: "Não"}
															</Descriptions.Item>
															{partner?.participation ? (
																<Descriptions.Item label="Participação">
																	{partner.participation}%
																</Descriptions.Item>
															) : null}

															{partner?.pis ? (
																<Descriptions.Item label="PIS">
																	{partner.pis}
																</Descriptions.Item>
															) : null}
															{partner.pro_labore ? (
																<Descriptions.Item label="Pro-labore">
																	{partner.pro_labore.toLocaleString("pt-BR", {
																		style: "currency",
																		currency: "BRL",
																	})}
																</Descriptions.Item>
															) : null}

															<Descriptions.Item span={3} label="Endereço">
																{renderAddress(partner.address)}
															</Descriptions.Item>
														</Descriptions>
													</Collapse.Panel>
												</>
											);
										})}
									</Collapse>
								</>
							) : null}
						</div>
					) : null}
				</>
			) : null}
		</Modal>
	);
};

export default Observations;
