import React, { useState, useEffect } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from 'lodash';

import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	DatePicker,
	notification,
	Alert
} from "antd";

import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { statuses } from "../Misc";
import Estados from 'functions/states';

import moment from 'moment';

const { Content } = Layout;
const { Title } = Typography;

function Contract({id, business_id}) {
	
	const [disabled, setDisabled] = useState(true);
	const [state, setState] = useState(null);

	const [saveAction, { loading: loadingSave }] = useMutation(Queries.CONTRACT_SAVE);

	const { loading, data, error, refetch } = useQuery(Queries.CONTRACT, {
		fetchPolicy: 'no-cache',
		variables: {
			uid: id,
			business_id
		}
	});

	useEffect(() => {
		if(data) {
			setState({
				signature_date: data.Contract.signature_date || null,
				responsibility_start: data.Contract.responsibility_start || null
			});
		}
	}, [data]);
	
	const getValue = (path) => {
		return _.get(state, path);
	}

	const setValue = (path, value) => {
		setState(state => {
			_.set(state, path, value);
			return state;
		})
	}

	const ErrorDialog = (msg) => {
				Swal.fire({
					title: "Erro",
					text: msg,
					type: "error",
					confirmButtonText: "OK"
				});
	}

	const save = async () => {

		const {responsibility_start, signature_date} = state;

		const {data, error} = await saveAction({
			variables: {
				input: {
					responsibility_start,
					signature_date
				},
				uid: id,
				business_id
			}
		});


		if(error) {
			ErrorDialog('Não foi possível salvar as informações no momento. Tente novamente mais tarde.');
		} else {
			notification.success({
				message: 'Sucesso',
				description:
				  'Informações salvas com sucesso',
			});
			refetch();
		}

	}


	return (
		<Card title="Contrato" extra={(<Button onClick={() => setDisabled(state => !state)}>{disabled ? 'Editar' : 'Cancelar'}</Button>)}>

				{
					(loading) ? (
						<Loading/>
					) : null
				}


				{
					(!loading && error) ? (
						<Error refetch={refetch}/>
					) : null
				}
			
				{
					(state && !loading && !error) ? (
						<Form layout="vertical">
							<Row gutter={20}>

								<Col span={12}>

									{
										!state.signature_date ? (
											<div style={{marginBottom: '20px'}}>
												<Alert message="Não foi possível localizar o contrato. Por favor, defina as datas." type="error" />
											</div>
										) : null
									}

									<Form.Item label="Data de Assinatura">

										<DatePicker 
											format={'DD/MM/YYYY'} 
											defaultValue={getValue('signature_date') ? moment(getValue('signature_date')) : null} 
											disabled={disabled}
											onChange={(e) => {
												setValue('signature_date', e.toDate())
											}}
											style={{
												width: '100%'
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Data de Início de Responsabilidade">
										<DatePicker 
											defaultValue={getValue('responsibility_start') ? moment(getValue('responsibility_start')) : null} 
											format={'MM/YYYY'} 
											disabled={disabled}
											onChange={(e) => {
												setValue('responsibility_start', e.toDate())
											}}
											style={{
												width: '100%'
											}}
										/>
									</Form.Item>
								</Col>
								
								{
									!disabled ? (
										<Col span={24}>
											<Button 
												type="primary" 
												style={{float: 'right'}}
												loading={loadingSave}
												onClick={save}
											>Salvar</Button>
										</Col>
									) : null
								}

							</Row>
						</Form>
					) : null
				}

		</Card>
	);
}

export default Contract;
