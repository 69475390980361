import React, { useEffect, useState } from "react";
import {
	Button,
	Modal,
	Form,
	Checkbox,
	Input,
	DatePicker,
	Row,
	Col,
	Tabs,
	Spin,
	Table,
	Badge,
	Select,
	Upload,
	List,
	notification,
	Empty,
} from "antd";

import { TICKETS } from "../tickets/Queries";
import { ADMINS } from "../admins/Queries";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { useImmer } from "use-immer";
import InputNumber from "components/InputNumber";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const badgeColors = {
	0: "#fdc649",
	1: "#1494fc",
	2: "#fe961e",
	3: "#50ad54",
	4: "#9e9e9e",
};

const App = ({ visible, close, id }) => {
	const [load] = useLazyQuery(
		gql`
		query UserUAU($user_id: String!) {
			UserUAU(user_id: $user_id) {
				contact_form
				day_period_contact
				profile
				accountant_knowledge
				reference
				reference_other
				preferred_admins
				last_contact
				last_contact_reason
				suggestions
				uau_info
				onboarding {
					admin
					date
					channel
					status
					topics
					topics_other
				}
				finance {
					tax_type
					contract_file
					conditions
					conditions_special
					value
					payment_method
					bank_slip_receive
					bank_slip_email
				}
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				if (data?.UserUAU) {
					let response = { ...data.UserUAU };

					if (response?.onboarding?.date) {
						response.onboarding.date = moment(response.onboarding.date);
					}

					if (response.last_contact)
						response.last_contact = moment(response.last_contact);
					setState(response);
				}
			},
			variables: {
				user_id: id,
			},
		}
	);

	const [loadReviews, reviews] = useLazyQuery(
		gql`
		query UserReviews($user_id: String!) {
			UserReviews(user_id: $user_id) {
				rate
				description
				createdAt
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				user_id: id,
			},
		}
	);

	const [saveAction, { loading }] = useMutation(gql`
		mutation UserUAU($input: UserUAUInput, $user_id: String!) {
			UserUAU(input: $input, user_id: $user_id)
		}
	`);

	const [loadTickets, tickets] = useLazyQuery(TICKETS, {
		variables: {
			input: {
				user_id: id,
				all: true,
				status: "",
			},
		},
		fetchPolicy: "no-cache",
	});

	const [loadAdmins, admins] = useLazyQuery(ADMINS, {
		fetchPolicy: "no-cache",
	});

	const [nps, setNps] = useImmer({
		visible: false,
	});
	const [state, setState] = useImmer({});

	useEffect(() => {
		if (visible) {
			loadTickets();
			loadAdmins();
			loadReviews();
			load();
		}
	}, [visible]);

	const save = async () => {
		try {
			let payload = { ...state };


			if (payload?.finance?.hasOwnProperty("contract_file")) {
				const { contract_file, ...finance } = payload.finance;
				payload.finance = finance;
			}

			if (payload.last_contact)
				payload.last_contact = payload.last_contact.toISOString();

			console.log(payload);

			let response = await saveAction({
				variables: {
					input: payload,
					user_id: id,
				},
			});

			if (response?.data?.UserUAU) {
				load();
				notification.success({
					message: "Sucesso",
					description: "Informações atualizadas com sucesso",
				});
			} else {
				notification.error({
					message: "Erro",
					description: "Não foi possível atualizar as informações no momento.",
				});
			}
		} catch (error) {
			console.log(error);
			notification.error({
				message: "Erro",
				description: "Não foi possível atualizar as informações no momento.",
			});
		}
	};

	const onClose = () => {
		setState({});
		close();
	};

	console.log(loading);

	return (
		<>
			<Modal
				title="UAU"
				visible={visible}
				onOk={() => {
					save();
				}}
				width={800}
				onCancel={() => {
					onClose();
				}}
				footer={[
					<Button
						key="back"
						onClick={() => {
							onClose();
						}}
						disabled={loading}
					>
						Cancelar
					</Button>,
					<Button
						type="primary"
						loading={loading}
						disabled={loading}
						onClick={save}
					>
						Salvar
					</Button>,
				]}
			>
				<Tabs>
					<Tabs.TabPane tab="Sobre" key="item-1">
						<Form layout="vertical">
							<Form.Item label={"Melhor forma de contato"}>
								<Checkbox.Group
									className="vertical-group"
									options={[
										{
											label: "E-mail",
											value: "email",
										},
										{
											label: "WhatsApp",
											value: "whatsapp",
										},
										{
											label: "Telefone",
											value: "telefone",
										},
									]}
									value={state?.contact_form ?? []}
									onChange={(e) => {
										setState((d) => {
											d.contact_form = e;
										});
									}}
								/>
							</Form.Item>
							<Form.Item label={"Período do dia que prefere ser contactado"}>
								<Checkbox.Group
									className="vertical-group"
									options={[
										{
											label: "Manhã",
											value: "manha",
										},
										{
											label: "Tarde",
											value: "tarde",
										},
										{
											label: "Indiferente",
											value: "indiferente",
										},
									]}
									value={state?.day_period_contact ?? []}
									onChange={(e) => {
										setState((d) => {
											d.day_period_contact = e;
										});
									}}
								/>
							</Form.Item>
							<Form.Item label={"Perfil"}>
								<Checkbox.Group
									className="vertical-group"
									options={[
										{
											label: "Amigável",
											value: "amigavel",
										},
										{
											label: "Sério",
											value: "serio",
										},
										{
											label: "Descontraido",
											value: "descontraido",
										},
										{
											label: "Desconfiado",
											value: "desconfiado",
										},
										{
											label: "Direto/Objetivo",
											value: "direto_objetivo",
										},
										{
											label: "Moderado",
											value: "moderado",
										},
									]}
									value={state?.profile ?? []}
									onChange={(e) => {
										setState((d) => {
											d.profile = e;
										});
									}}
								/>
							</Form.Item>
							<Form.Item label={"Conhecimento sobre processos contábeis"}>
								<Checkbox.Group
									className="vertical-group"
									options={[
										{
											label: "Totalmente inexperiente",
											value: "totalmente_inexperiente",
										},
										{
											label: "Experiência Básica",
											value: "exp_basica",
										},
										{
											label: "Bom conhecimento",
											value: "bom_conhecimento",
										},
										{
											label: "Conhecimento Avançado",
											value: "conhecimento_avancado",
										},
									]}
									value={state?.accountant_knowledge ?? []}
									onChange={(e) => {
										setState((d) => {
											d.accountant_knowledge = e;
										});
									}}
								/>
							</Form.Item>

							<Form.Item label={"Como chegou até nós"}>
								<Checkbox.Group
									className="vertical-group"
									options={[
										{
											label: "Redes sociais",
											value: "redes_sociais",
										},
										{
											label: "Google",
											value: "google",
										},
										{
											label: "Indicação de Amigo",
											value: "indicacao_amigo",
										},
										{
											label: "Indicação empresa que presta serviços",
											value: "indicacao_empresa",
										},
										{
											label: "Outros",
											value: "outros",
										},
									]}
									value={state?.reference ?? []}
									onChange={(e) => {
										setState((d) => {
											d.reference = e;
										});
									}}
								/>
							</Form.Item>

							<Form.Item label={"Gosta de ser atendido por alguém especifico?"}>
								<Select
									mode="multiple"
									allowClear
									style={{ width: "100%" }}
									placeholder="Selecione"
									value={state?.preferred_admins}
									options={
										admins?.data?.Admins?.length
											? admins.data.Admins.map((i) => {
													return {
														label: i.name,
														value: i._id,
													};
											  })
											: []
									}
									onChange={(e) => {
										setState((d) => {
											d.preferred_admins = e;
										});
									}}
								/>
							</Form.Item>

							<Form.Item label={"Data de último contato e motivo"}>
								<Row gutter={20}>
									<Col span={12}>
										<DatePicker
											style={{ width: "100%" }}
											format={"DD/MM/YYYY"}
											value={state.last_contact ?? null}
											onChange={(e) => {
												setState((d) => {
													d.last_contact = e;
												});
											}}
										/>
									</Col>
									<Col span={12}>
										<Input
											placeholder="Motivo"
											value={state?.last_contact_reason ?? null}
											onChange={(e) => {
												setState((d) => {
													d.last_contact_reason = e.target.value;
												});
											}}
										/>
									</Col>
								</Row>
							</Form.Item>
							<Form.Item label={"Sugestão feita"}>
								<Input.TextArea
									rows={10}
									value={state?.suggestions ?? null}
									onChange={(e) => {
										setState((d) => {
											d.suggestions = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Form>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Informações UAU" key="item-2">
						<Input.TextArea
							rows={10}
							value={state?.uau_info ?? null}
							onChange={(e) => {
								setState((d) => {
									d.uau_info = e.target.value;
								});
							}}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Financeiro" key="item-6">
						<Form layout="vertical">
							<Row gutter={20}>
								<Col span={12}>
									<Form.Item label={"Regime Tributário"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.finance?.tax_type}
											options={Array.from([
												"Simples Nacional",
												"Lucro Presumido",
											]).map((i) => {
												return {
													value: i,
													label: i,
												};
											})}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.tax_type = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Contrato"}>
										<div style={{display: 'flex', gap: 10}}>
											<Upload
												fileList={
													state?.finance?.contract
														? [state?.finance?.contract]
														: []
												}
												beforeUpload={() => false}
												maxCount={1}
												accept={".pdf,.jpg,.gif,.png"}
												onChange={(e) => {
													setState((d) => {
														if (!d.finance) d.finance = {};
														d.finance.contract = e.file;
													});
												}}
											>
												<Button icon={<UploadOutlined />}>Selecionar</Button>
											</Upload>
											{state?.finance?.contract_file ? (
												<Button
													type="primary"
													onClick={() => {
														window.open(state?.finance?.contract_file);
													}}
												>
													Ver
												</Button>
											) : null}
										</div>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label={"Condições"}>
										<Input.TextArea
											value={state?.finance?.conditions}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.conditions = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label={"Condição Especial"}>
										<Input.TextArea
											value={state?.finance?.conditions_special}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.conditions_special = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Valor Acordado"}>
										<InputNumber
											value={state?.finance?.value}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.value = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Forma de Pagamento"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.finance?.payment_method}
											options={Array.from(["PIX", "Cartão", "Boleto"]).map(
												(i) => {
													return {
														value: i,
														label: i,
													};
												}
											)}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.payment_method = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Como deseja receber o boleto"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.finance?.bank_slip_receive}
											options={Array.from(["E-mail", "WhatsApp"]).map((i) => {
												return {
													value: i,
													label: i,
												};
											})}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.bank_slip_receive = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"E-mail para envio do boleto"}>
										<Input
											value={state?.finance?.bank_slip_email}
											onChange={(e) => {
												setState((d) => {
													if (!d.finance) d.finance = {};
													d.finance.bank_slip_email = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Onboarding" key="item-5">
						<Form layout="vertical">
							<Row gutter={20}>
								<Col span={12}>
									<Form.Item label={"Colaborador"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.onboarding?.admin}
											options={
												admins?.data?.Admins?.length
													? admins.data.Admins.map((i) => {
															return {
																label: i.name,
																value: i._id,
															};
													  })
													: []
											}
											onChange={(e) => {
												console.log(e);
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.admin = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Data"}>
										<DatePicker
											style={{ width: "100%" }}
											showTime
											format={"DD/MM/YYYY HH:mm:ss"}
											value={state?.onboarding?.date ?? null}
											onChange={(e) => {
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.date = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Canal"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.onboarding?.channel}
											options={Array.from([
												"Google Meet",
												"Chat",
												"E-mail",
											]).map((i) => {
												return {
													value: i,
													label: i,
												};
											})}
											onChange={(e) => {
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.channel = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Status"}>
										<Select
											allowClear
											style={{ width: "100%" }}
											placeholder="Selecione"
											value={state?.onboarding?.status}
											options={Array.from([
												"Realizado",
												"Não Realizado",
												"Agendado",
											]).map((i) => {
												return {
													value: i,
													label: i,
												};
											})}
											onChange={(e) => {
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.status = e;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label={"Tópicos"}>
										<Checkbox.Group
											className="vertical-group"
											options={Array.from([
												"Condições comerciais acordadas",
												"Acesso ao App",
												"Funções do App",
												"Programa de Indicação",
												"Serviços inclusos e avulsos",
												"Soluti",
												"Banco Cora",
												"Orientações Fiscal/Contábil",
												"Outros",
											]).map((i) => {
												return {
													value: i,
													label: i,
												};
											})}
											value={state?.onboarding?.topics ?? []}
											onChange={(e) => {
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.topics = e;
												});
											}}
										/>
									</Form.Item>

									{state?.onboarding?.topics?.indexOf("Outros") > -1 ? (
										<Input
											placeholder="Outros"
											value={state?.onboarding?.topics_other}
											onChange={(e) => {
												setState((d) => {
													if (!d.onboarding) d.onboarding = {};
													d.onboarding.topics_other = e.target.value;
												});
											}}
										/>
									) : null}
								</Col>
							</Row>
						</Form>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Histórico de Solicitações" key="item-3">
						{tickets?.loading ? (
							<div style={{ padding: 20, textAlign: "center" }}>
								<Spin />
							</div>
						) : null}

						{!tickets.loading && tickets?.data?.Tickets ? (
							<Table
								dataSource={tickets.data.Tickets}
								columns={[
									{
										key: "name",
										dataIndex: "name",
										render: (value, row) => value ?? "-",
										title: "Serviço",
									},
									{
										key: "createdAt",
										dataIndex: "createdAt",
										title: "Data",
										render: (value) => dayjs(value).format("DD/MM/YYYY"),
									},
									{
										key: "status",
										dataIndex: "status",
										width: 170,
										render: (value, row) => (
											<>
												<Badge color={badgeColors[value]} />
												{value === 0 ? "Aguardando" : null}
												{value === 1 ? "Em andamento" : null}
												{value === 2 ? "Aguardando cliente" : null}
												{value === 3 ? "Resolvido" : null}
												{value === 4 ? "Cancelado" : null}
											</>
										),
										title: "Status",
									},
								]}
								pagination={{
									hideOnSinglePage: true,
								}}
							/>
						) : null}
					</Tabs.TabPane>
					<Tabs.TabPane tab="NPS" key="item-4">
						{reviews?.loading ? <Loading /> : null}

						{!reviews.loading && reviews?.data?.UserReviews?.length ? (
							<List
								itemLayout="horizontal"
								dataSource={reviews.data.UserReviews}
								renderItem={(item) => (
									<List.Item className="rate-item">
										<div className="rate-view">
											{Array(item.rate)
												.fill()
												.map(function (v, i) {
													return <StarFilled />;
												})}
											{Array(10 - item.rate)
												.fill()
												.map(function (v, i) {
													return <StarOutlined className="outline" />;
												})}
										</div>
										<div className="rate-description">
											{item.description}
											<em>
												{dayjs(item.createdAt).format("DD/MM/YYYY HH:mm:ss")}
											</em>
										</div>
									</List.Item>
								)}
							/>
						) : null}

						{!reviews?.loading && !reviews?.data?.UserReviews?.length ? (
							<Empty />
						) : null}

						{!reviews?.loading && reviews.error ? <Error /> : null}
					</Tabs.TabPane>
				</Tabs>
			</Modal>
		</>
	);
};
export default App;
