import React, { useState } from "react";
import * as Queries from "./Queries";
import { useQuery } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import BusinessStatus from "scenes/users/util/BusinessStatus";
import { Card, Col, Row, Layout, Typography, Divider, Badge, List } from "antd";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;

function Dashboard() {
	const { loading, data, error, refetch } = useQuery(Queries.DASHBOARD, {
		fetchPolicy: "no-cache",
	});

	const getBusinessStatus = (id) => {
		let status = BusinessStatus();
		let filter = status.find((i) => i.value === id);
		if (filter) return filter.label;
		return "Pendente";
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div className="dashboard-view">
							<Row gutter={20}>
								<Col span={12}>
									<Title>Parceiros</Title>
									<Row gutter={20}>
										<Col span={12}>
											<Card
												size="small"
												title={<div className="info">Ativos</div>}
											>
												<h4>{data.Dashboard?.partners?.active || "0"}</h4>
											</Card>
										</Col>
										<Col span={12}>
											<Card
												size="small"
												title={<div className="danger">Inativos</div>}
											>
												<h4>{data.Dashboard?.partners?.inactive || "0"}</h4>
											</Card>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<Title>Empresas</Title>
									<List
										dataSource={data?.Dashboard?.businesses}
										renderItem={(item) => {
											let itemId = item._id ?? "";
											if (item._id) {
												return (
													<List.Item>
														<Link to={`users?business_status=${itemId}`}>
															{getBusinessStatus(item._id)}
														</Link>
														<Badge text={item.count} status="success" />
													</List.Item>
												);
											}
										}}
									/>
								</Col>
							</Row>

							<Title>Tickets</Title>

							<Row gutter={16}>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="danger">Aguardando</div>}
									>
										<h4>{data.Dashboard?.tickets?.awaiting || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="info">Em progresso</div>}
									>
										<h4>{data.Dashboard?.tickets?.in_progress || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										size="small"
										title={<div className="solved">Resolvido</div>}
									>
										<h4>{data.Dashboard?.tickets?.solved || "0"}</h4>
									</Card>
								</Col>
								<Col span={6}>
									<Card size="small" title="Aguardando Cliente">
										<h4>{data.Dashboard?.tickets?.waiting_customer || "0"}</h4>
									</Card>
								</Col>
							</Row>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Dashboard;
