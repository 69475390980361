import {
	Button,
	Modal,
	Tabs,
	Form,
	Row,
	Col,
	Radio,
	Input,
	Select,
	List,
	Table,
	DatePicker,
	Checkbox,
	Rate,
	Tooltip,
	Popconfirm,
	notification,
} from "antd";
import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import moment from "moment";

import { EditOutlined, MinusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";

import { ADMINS } from "scenes/tickets/Queries";

const Interaction = ({ visible, close, uau_id, id, refetch }) => {
	const [state, setState] = useImmer({});

	const [loadAdmins, admins] = useLazyQuery(ADMINS, {
		fetchPolicy: "no-cache",
	});

	const [loadData] = useLazyQuery(
		gql`
		query UAU_Interaction($id: String!, $uau_id: String!) {
			UAU_Interaction(id: $id, uau_id: $uau_id) {
				closer
				meeting_date
				meeting_status
				reaction
				profile
				profile_others
				interest_points
				interest_points_others
				negative_points
				negative_points_other
				positive_points
				positive_points_other
				offer
				objections
				offered_plan
				conditions
				rating_chance
				status
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				if (data.UAU_Interaction) {
					let input = { ...data.UAU_Interaction };
					if (input.meeting_date)
						input.meeting_date = moment(input.meeting_date);
					setState(input);
				}
			},
			onError: () => {
				Modal.error({
					title: "Erro",
					content: "Não foi possível carregar as informações",
				});
			},
		}
	);

	const [saveAction, { loading }] = useMutation(
		gql`
		mutation UAU_Interaction($uau_id: String!
		$id: String
		$input: UAU_Interaction_Input!) {
			UAU_Interaction(uau_id: $uau_id, id: $id, input: $input)
		}
	`,
		{
			onCompleted: (data) => {
				if (data.UAU_Interaction) {
					refetch();
					onClose();
				} else {
					Modal.error({
						title: "Erro",
						content: "Não foi possível salvar no momento.",
					});
				}
			},
			onError: () => {
				Modal.error({
					title: "Erro",
					content: "Não foi possível salvar no momento.",
				});
			},
		}
	);

	const save = async () => {
		if (!state.closer)
			return Modal.error({
				title: "Erro",
				content: "Por favor, selecione um closer.",
			});
		if (!state.meeting_date)
			return Modal.error({
				title: "Erro",
				content: "Por favor, selecione uma data da reunião.",
			});
		if (!state.meeting_status)
			return Modal.error({
				title: "Erro",
				content: "Por favor, selecione um status.",
			});

		let payload = { ...state };
		payload.meeting_date = payload.meeting_date.startOf("day").toISOString();

		await saveAction({
			variables: {
				input: payload,
				uau_id,
				id: id ?? null,
			},
		});
	};

	useEffect(() => {
		if (visible) loadAdmins();
		if (visible && id && uau_id)
			loadData({
				variables: {
					id,
					uau_id,
				},
			});
	}, [visible]);

	const onClose = () => {
		setState({});
		close();
	};

	return (
		<Modal
			title={uau_id ? "Editar Interação" : "Nova Interação"}
			width={800}
			visible={visible}
			onOk={() => {
				save();
				//close();
			}}
			onCancel={onClose}
		>
			<Form layout="vertical">
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item label={"Data da Reunião"}>
							<DatePicker
								format={"DD/MM/YYYY"}
								style={{ width: "100%" }}
								value={state?.meeting_date}
								onChange={(e) => {
									setState((d) => {
										d.meeting_date = e;
									});
								}}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={"Status da Reunião"}>
							<Select
								value={state.meeting_status}
								onChange={(e) => {
									setState((d) => {
										d.meeting_status = e;
									});
								}}
							>
								<Select.Option value={"pending"}>Pendente</Select.Option>
								<Select.Option value={"done"}>Realizada</Select.Option>
								<Select.Option value={"noshow"}>No show</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Closer">
							<Select
								value={state?.closer ?? null}
								loading={admins?.loading}
								onChange={(e) => {
									setState((d) => {
										d.closer = e;
									});
								}}
							>
								{admins?.data &&
									admins?.data?.Admins?.map((item, index) => {
										return (
											<Select.Option key={`admin_id_${index}`} value={item._id}>
												{item.name}
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
					</Col>
					{state?.meeting_status === "done" ? (
						<>
							<Col span={24}>
								<Form.Item label={"Perfil do cliente"}>
									<Radio.Group
										className="spaced"
										onChange={(e) => {
											setState((d) => {
												d["profile"] = e.target.value;
											});
										}}
										value={state?.profile}
									>
										{Array.from([
											"Descontraido",
											"Sério",
											"Frio",
											"Animado",
											"Desconcentrado",
											"Direto/Objetivo",
											"Questionador",
											"Desanimado",
											"Comunicativo",
											"Outro",
										]).map((item, idx) => {
											return (
												<Radio value={item} key={`int_profile_${idx}`}>
													{item}
												</Radio>
											);
										})}
									</Radio.Group>
									{state?.profile === "Outro" ? (
										<Form.Item label={""}>
											<Input
												placeholder="Outro"
												onChange={(e) => {
													setState((d) => {
														d["profile_others"] = e.target.value;
													});
												}}
												value={state?.profile_others}
											/>
										</Form.Item>
									) : null}
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item label={"Reação do cliente perante a reunião"}>
									<Radio.Group
										onChange={(e) => {
											setState((d) => {
												d["reaction"] = e.target.value;
											});
										}}
										value={state?.reaction}
									>
										{Array.from([
											"Impressionado",
											"Indiferente",
											"Não satisfeito",
											"Gostou",
											"Outro",
										]).map((item, idx) => {
											return (
												<Radio value={item} key={`reaction_${idx}`}>
													{item}
												</Radio>
											);
										})}
									</Radio.Group>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item label={"Pontos quem chamam atenção"}>
									<Checkbox.Group
										className="spaced"
										onChange={(e) => {
											setState((d) => {
												d["positive_points"] = e;
											});
										}}
										value={state?.positive_points}
										options={[
											"Atendimento",
											"App",
											"Agilidade",
											"Preços",
											"Outro",
										]}
									/>
								</Form.Item>
								{state?.positive_points?.length &&
								state?.positive_points?.indexOf("Outro") !== -1 ? (
									<Form.Item label={""}>
										<Input
											placeholder="Outro"
											onChange={(e) => {
												setState((d) => {
													d["positive_points_other"] = e.target.value;
												});
											}}
											value={state?.positive_points_other}
										/>
									</Form.Item>
								) : null}
							</Col>

							<Col span={24}>
								<Form.Item label={"Pontos negativos"}>
									<Checkbox.Group
										className="spaced"
										onChange={(e) => {
											setState((d) => {
												d["negative_points"] = e;
											});
										}}
										value={state?.negative_points}
										options={[
											"Preço",
											"Tecnologia",
											"Atendimento",
											"Demora",
											"Outro",
										]}
									/>
								</Form.Item>
								{state?.negative_points?.length &&
								state?.negative_points?.indexOf("Outro") !== -1 ? (
									<Form.Item label={""}>
										<Input
											placeholder="Outro"
											onChange={(e) => {
												setState((d) => {
													d["negative_points_other"] = e.target.value;
												});
											}}
											value={state?.negative_points_other}
										/>
									</Form.Item>
								) : null}
							</Col>
							<Col span={24}>
								<Form.Item label={"Objeções a vencer"}>
									<Input.TextArea
										onChange={(e) => {
											setState((d) => {
												d["objections"] = e.target.value;
											});
										}}
										value={state?.objections}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item label={"O que oferecer:"}>
									<Input.TextArea
										onChange={(e) => {
											setState((d) => {
												d["offer"] = e.target.value;
											});
										}}
										value={state?.offer}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item label={"Plano Oferecido"}>
									<Input.TextArea
										onChange={(e) => {
											setState((d) => {
												d["offered_plan"] = e.target.value;
											});
										}}
										value={state?.offered_plan}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item label={"Condições"}>
									<Input.TextArea
										onChange={(e) => {
											setState((d) => {
												d["conditions"] = e.target.value;
											});
										}}
										value={state?.conditions}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item
									label={"De 1 a 10, qual é a chance do cliente fechar?"}
								>
									<Rate
										onChange={(e) => {
											setState((d) => {
												d["rating_chance"] = e;
											});
										}}
										value={state?.rating_chance}
										count={10}
									/>
								</Form.Item>
							</Col>
						</>
					) : null}
				</Row>
			</Form>
		</Modal>
	);
};

const Uau = ({ visible, close, id, refetch }) => {
	const [state, setState] = useImmer({
		profile: {},
	});

	const [loadData] = useLazyQuery(
		gql`
		query UAU($id: String) {
			UAU(id: $id) {
				type
				client_name
				partner_id
				partner_name
				company_name
				meis
				category
				has_partner
				contact_name
				contact_gender
				contacts {
					type
					value
				}
				age
				number_employees
				marketing_area
				marketing_digital
				marketing_digital_tools
				marketing_digital_networks
				marketing_digital_tools_other
				reference
				signup_date
				status
				sdr
				scheduled_meeting
				funnil
				observations
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				if (data.UAU.signup_date)
					data.UAU.signup_date = moment(data.signup_date);
				setState({
					profile: data.UAU,
				});
			},
		}
	);

	const [loadCategories, categories] = useLazyQuery(
		gql`
		{
			UAU_Categories {
				name
				_id
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [loadPartners, partners] = useLazyQuery(
		gql`
	query Partners {
	  Partners {
		 _id
		 cnpj
		 company_name
		 trade_name
		 phone
		 email
		 plan_id
		 status
		 balance
	  }
	}
 `,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [loadInteractions, interactions] = useLazyQuery(
		gql`
		query UAU_Interactions($uau_id: String!) {
			UAU_Interactions(uau_id: $uau_id) {
				meeting_date
				status
				closer
				rate
				_id
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [saveAction, { loading }] = useMutation(
		gql`
		mutation UAU($id: String, $input: UAUProfileInput!) {
			UAU(id: $id, input: $input)
		}
	`,
		{
			onCompleted: () => {
				notification.success({
					message: "Informações atualizadas com sucesso!",
				});
				refetch();
			},
			onError: () => {
				notification.error({
					message: "Não foi possível atualizar no momento",
				});
			},
		}
	);

	const [interaction, setInteraction] = useImmer({
		visible: false,
	});

	const save = async () => {
		let payload = { ...state.profile };
		if (payload.signup_date)
			payload.signup_date = payload.signup_date.startOf("day").toISOString();
		if (payload.meis) payload.meis = parseInt(payload.meis);
		if (payload.number_employees)
			payload.number_employees = parseInt(payload.number_employees);

		await saveAction({
			variables: {
				input: payload,
				id: id ?? null,
			},
		});
	};

	useEffect(() => {
		if (visible) {
			loadCategories();
			loadPartners();
		}

		if (visible && id) {
			loadData({
				variables: {
					id,
				},
			});

			loadInteractions({
				variables: {
					uau_id: id,
				},
			});
		}
	}, [visible]);

	const onClose = () => {
		close();
		setState({
			profile: {},
		});
	};

	return (
		<>
			<Modal
				title="UAU CRM"
				width={800}
				visible={visible}
				onCancel={() => {
					onClose();
				}}
				footer={[
					<Button
						key="back"
						onClick={() => {
							onClose();
						}}
						disabled={loading}
					>
						Cancelar
					</Button>,
					<Button
						type="primary"
						loading={loading}
						disabled={loading}
						onClick={save}
					>
						Enviar
					</Button>,
				]}
			>
				<Tabs>
					<Tabs.TabPane tab="Perfil" key="item-1">
						<Form layout="vertical">
							<Row gutter={20}>
								<Col span={24}>
									<Form.Item label={"Nome do Cliente"}>
										<Input
											value={state?.profile?.client_name}
											onChange={(e) => {
												setState((d) => {
													d["profile"]["client_name"] = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Ramo"}>
										<Select
											onChange={(e) => {
												setState((d) => {
													d["profile"]["category"] = e;
												});
											}}
											value={state?.profile?.category}
										>
											{categories?.data?.UAU_Categories?.length
												? categories?.data?.UAU_Categories.map(
														(item, index) => {
															return (
																<Select.Option
																	value={item._id}
																	key={`cat_${item._id}`}
																>
																	{item.name}
																</Select.Option>
															);
														}
												  )
												: null}
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label={"Parceiro"}>
										<Select
											onChange={(e) => {
												setState((d) => {
													d["profile"]["partner_id"] = e;
												});
											}}
											value={state?.profile?.partner_name}
										>
											{partners?.data?.Partners?.length
												? partners?.data?.Partners.map((item, index) => {
														return (
															<Select.Option
																value={item._id}
																key={`partner_${item._id}`}
															>
																{item.company_name}
															</Select.Option>
														);
												  })
												: null}
										</Select>
									</Form.Item>
								</Col>

								<Col span={14}>
									<Form.Item label={"Nome do contato"}>
										<Input
											value={state?.profile?.contact_name}
											onChange={(e) => {
												setState((d) => {
													d["profile"]["contact_name"] = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item label={"Gênero"}>
										<Select
											onChange={(e) => {
												setState((d) => {
													d["profile"]["contact_gender"] = e;
												});
											}}
											value={state?.profile?.contact_gender}
										>
											<Select.Option value={"Homem"}>Homem</Select.Option>
											<Select.Option value={"Mulher"}>Mulher</Select.Option>
											<Select.Option value={"Outro"}>Outro</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={4}>
									<Form.Item label={"Tem sócio?"}>
										<Select
											onChange={(e) => {
												setState((d) => {
													d["profile"]["has_partner"] = e;
												});
											}}
											value={state?.profile?.has_partner}
										>
											<Select.Option value={"yes"}>Sim</Select.Option>
											<Select.Option value={"no"}>Não</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label={"Contatos"}>
										<div>
											<Table
												style={{
													marginBottom: 10,
												}}
												dataSource={state?.profile?.contacts}
												pagination={{
													hideOnSinglePage: true,
												}}
												columns={[
													{
														key: "type",
														dataIndex: "type",
														title: "Tipo",
														width: 200,
														render: (value, row, index) => (
															<Select
																onChange={(e) => {
																	setState((d) => {
																		d.profile.contacts[index].type = e;
																	});
																}}
																value={value}
															>
																<Select.Option value={"E-mail"}>
																	E-mail
																</Select.Option>
																<Select.Option value={"WhatsApp"}>
																	WhatsApp
																</Select.Option>
																<Select.Option value={"Telefone"}>
																	Telefone
																</Select.Option>
																<Select.Option value={"Redes Sociais"}>
																	Redes Sociais
																</Select.Option>
															</Select>
														),
													},
													{
														key: "value",
														dataIndex: "value",
														title: "Contato",
														render: (value, row, index) => (
															<div
																style={{
																	display: "flex",
																}}
															>
																<Input
																	value={value}
																	onChange={(e) => {
																		setState((d) => {
																			d.profile.contacts[index].value =
																				e.target.value;
																		});
																	}}
																/>
																<Popconfirm
																	title="Tem certeza que deseja remover?"
																	onConfirm={() => {
																		setState((d) => {
																			d.profile.contacts.splice(index, 1);
																		});
																	}}
																	onCancel={() => {}}
																	okText="Sim"
																	cancelText="Não"
																>
																	<Button
																		style={{ marginLeft: 10 }}
																		shape="circle"
																		icon={<MinusOutlined />}
																	/>
																</Popconfirm>
															</div>
														),
													},
												]}
											/>

											<div
												style={{
													textAlign: "right",
												}}
											>
												<Button
													onClick={() => {
														setState((d) => {
															if (!d?.profile?.contacts)
																d.profile.contacts = [];
															d.profile.contacts.push({
																type: null,
																value: null,
															});
														});
													}}
												>
													Adicionar
												</Button>
											</div>
										</div>
									</Form.Item>
								</Col>

								<Col span={"auto"}>
									<Form.Item label={"Como chegou até nós"}>
										<Radio.Group
											onChange={(e) => {
												setState((d) => {
													d["profile"]["reference"] = e.target.value;
												});
											}}
											value={state?.profile?.reference}
										>
											<Radio value={"Google"}>Google</Radio>
											<Radio value={"Instagram"}>Instagram</Radio>
											<Radio value={"Facebook"}>Facebook</Radio>
											<Radio value={"Prospeção nossa"}>Prospeção nossa</Radio>
											<Radio value={"Outros"}>Outros</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								{state?.profile?.reference === "Outros" ? (
									<Col span={6}>
										<Form.Item label={" "}>
											<Input
												value={state?.profile?.marketing_digital_tools_other}
												onChange={(e) => {
													setState((d) => {
														d["profile"]["marketing_digital_tools_other"] =
															e.target.value;
													});
												}}
											/>
										</Form.Item>
									</Col>
								) : null}
								<Col span={8}>
									<Form.Item label={"Data de Cadastro"}>
										<DatePicker
											onChange={(e) => {
												setState((d) => {
													d["profile"]["signup_date"] = e;
												});
											}}
											format={"DD/MM/YYYY"}
											style={{
												width: "100%",
											}}
											value={state?.profile?.signup_date}
										/>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item label={"SDR"}>
										<Input
											value={state?.profile?.sdr}
											onChange={(e) => {
												setState((d) => {
													d["profile"]["sdr"] = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item label={"Funil"}>
										<Radio.Group
											onChange={(e) => {
												setState((d) => {
													d["profile"]["funnil"] = e.target.value;
												});
											}}
											value={state?.profile?.funnil}
										>
											<Radio value={"Quente"}>Quente</Radio>
											<Radio value={"Morno"}>Morno</Radio>
											<Radio value={"Frio"}>Frio</Radio>
										</Radio.Group>
									</Form.Item>
								</Col>
								<Col span={16}>
									<Form.Item label={"Status"}>
										<Select
											onChange={(e) => {
												setState((d) => {
													d["profile"]["status"] = e;
												});
											}}
											value={state?.profile?.status}
										>
											<Select.Option value={"negotiating"}>
												Em negociação{" "}
											</Select.Option>
											<Select.Option value={"contract_sent"}>
												Contrato enviado
											</Select.Option>
											<Select.Option value={"contract_signed"}>
												Contrato assinado
											</Select.Option>
											<Select.Option value={"sending_documentation"}>
												Enviando Documentação
											</Select.Option>
											<Select.Option value={"client_dropped"}>
												Cliente desistiu
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label={"Observações"}>
										<Input.TextArea
											rows={5}
											value={state?.profile?.observations}
											onChange={(e) => {
												setState((d) => {
													d["profile"]["observations"] = e.target.value;
												});
											}}
										/>
									</Form.Item>
								</Col>

							
							</Row>
						</Form>
					</Tabs.TabPane>
					{id ? (
						<Tabs.TabPane tab="Interações" key="item-2">
							<div
								style={{
									textAlign: "right",
								}}
							>
								<Table
									dataSource={interactions?.data?.UAU_Interactions}
									columns={[
										{
											key: "meeting_date",
											dataIndex: "meeting_date",
											title: "Data da Reunião",
											render: (value) =>
												value ? moment(value).format("DD/MM/YYYY") : "-",
										},
										{
											key: "closer",
											dataIndex: "closer",
											title: "Closer",
										},
										{
											key: "rate",
											dataIndex: "rate",
											title: "Probabilidade",
											render: (value) => (value ? `${value * 10}%` : "-"),
										},
										{
											key: "status",
											dataIndex: "status",
											title: "Status",
											render: (value) => {
												if (value === "pending") return "Pendente";
												if (value === "done") return "Realizada";
												if (value === "noshow") return "No show";
											},
										},
										{
											key: "action",
											dataIndex: "action",
											title: "",
											width: 40,
											render: (value, row) => {
												return (
													<Button
														shape="circle"
														onClick={() => {
															setInteraction({
																visible: true,
																id: row._id,
															});
														}}
													>
														<EditOutlined />
													</Button>
												);
											},
										},
									]}
									pagination={{
										hideOnSinglePage: true,
									}}
									style={{
										marginBottom: 20,
									}}
								/>

								<Interaction
									{...interaction}
									uau_id={id}
									refetch={() => {
										interactions.refetch();
									}}
									close={() => {
										setInteraction({
											visible: false,
										});
									}}
								/>
								<Button
									onClick={() => {
										setInteraction({
											visible: true,
										});
									}}
								>
									Adicionar
								</Button>
							</div>
						</Tabs.TabPane>
					) : null}
				</Tabs>
			</Modal>
		</>
	);
};

export default Uau;
