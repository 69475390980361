import React, { useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	notification,
	Upload,
	Button,
	Select,
	Spin,
} from "antd";
import InputNumberCurrency from "components/InputNumber";
import { useImmer } from "use-immer";
import { useMutation, useQuery, useLazyQuery, gql } from "@apollo/client";
import { INVOICE_REQUEST } from "./Queries";
import Swal from "sweetalert2";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import UserSearch from "components/UserSearch";
import MaskedInputCPFCNPJ from "components/InputCPFCNPJ";

const { TextArea } = Input;

const ServiceModal = ({ visible, data, close, refetch }) => {
	const [state, setState] = useImmer({});
	const [save, { loading }] = useMutation(INVOICE_REQUEST);

	const ErrorDialog = (message) => {
		return Swal.fire({
			title: "Erro",
			text: message,
			icon: "error",
		});
	};

	const [loadUserBusiness, businesses] = useLazyQuery(
		gql`
	query UserBusinesses($uid: String!) {
	  UserBusinesses(uid: $uid) {
		 _id
		 company_name
		 trade_name
	  }
	}
 `,
		{
			fetchPolicy: "no-cache",
		}
	);

	const onClose = () => {
		setState({});
		close();
	};

	const [loadCTN, ctn] = useLazyQuery(
		gql`
		{
			InvoiceCTN {
				id
				text
			}
		},
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		if (data) {
			console.log(data);

			setState({
				...data,
			});

			if (data.user) {
				loadUserBusiness({
					variables: {
						uid: data.user,
					},
				});
			}
		}
		if (visible) {
			loadCTN();
		}
	}, [visible]);

	const proceed = async () => {
		// if (!state.taker)
		//    return ErrorDialog("Por favor, informe um tomador.");
		if (!state.user) return ErrorDialog("Por favor, selecione um usuário.");
		if (!state.business_id) return ErrorDialog("Por favor, selecione uma empresa.");
		// if (!state.amount)
		//    return ErrorDialog("Por favor, informe um valor.");
		// if (!state.description)
		//    return ErrorDialog("Por favor, informe uma descrição.");
		if (!state.status) return ErrorDialog("Por favor, informe um status.");

		try {
			const {
				_id,
				service_code_text,
				user_files,
				business_name,
				finishedAt,
				fileURL,
				user_name,
				createdAt,
				partner_company,
				...input
			} = state;

			let vars = {
				input: {
					...input,
				},
				id: _id || null,
			};

			console.log(vars);

			let res = await save({
				variables: vars,
			});

			if (res?.data?.Invoice?._id) {
				notification.success({
					message: "Sucesso",
					description: "Nota Fiscal foi criada com sucesso!",
				});
				close();
				setState({});
				refetch();
			} else {
				ErrorDialog("Não foi possível enviar a solicitação no momento.");
			}
		} catch (error) {
			return ErrorDialog(
				error?.message || "Não foi possível enviar a solicitação no momento."
			);
		}
	};

	console.log(businesses);

	return (
		<Modal
			title={data?._id ? "Editar Nota Fiscal" : "Criar Nota Fiscal"}
			visible={visible}
			onOk={proceed}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={proceed}
				>
					Salvar
				</Button>,
			]}
		>
			<Form layout={"vertical"}>
				{visible && (
					<>
						<Form.Item label="Usuário">
							<UserSearch
								style={{ width: "100%" }}
								value={state?.user_name}
								onChange={(value, data) => {
									let user_name = data.name;
									setState((d) => {
										d.user = value;
										d.user_name = user_name;
										d.business_id = null;
									});

									loadUserBusiness({
										variables: {
											uid: value
										}
									})
								}}
							/>
						</Form.Item>
						<Form.Item label="Empresa">
							<Select
								value={state?.business_id}
								onChange={(e) => {
									setState(d => {
										d.business_id = e;
									})
								}}
							>
								{businesses?.data?.UserBusinesses?.length
									? businesses?.data?.UserBusinesses.map((item, index) => {
											return (
												<Select.Option
													value={item._id}
													key={`company_${item._id}`}
												>
													{item.company_name}
												</Select.Option>
											);
									  })
									: null}
							</Select>
						</Form.Item>
					</>
				)}

				<Form.Item label="Tomador">
					<Input
						value={state?.taker || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.taker = value;
							});
						}}
					/>
				</Form.Item>

				{visible && (
					<Form.Item label="CPF / CNPJ">
						<MaskedInputCPFCNPJ
							value={state?.cpfcnpj || null}
							onChange={(e) => {
								const { value } = e.target;
								setState((d) => {
									d.cpfcnpj = value;
								});
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Valor">
					<InputNumberCurrency
						style={{ width: "100%" }}
						value={state?.amount || 0}
						onChange={(value) => {
							setState((d) => {
								d.amount = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Código de Tributação Nacional">
					{ctn?.loading ? (
						<Spin />
					) : (
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return (
									option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
									-1
								);
							}}
							onChange={(e) => {
								setState((d) => {
									d.service_code = e;
								});
							}}
							value={state?.service_code || null}
							options={
								ctn?.data?.InvoiceCTN?.length
									? ctn?.data?.InvoiceCTN.map((item) => {
											return {
												label: item.text,
												value: item.id,
											};
									  })
									: []
							}
						/>
					)}
				</Form.Item>

				<Form.Item label="Descrição">
					<TextArea
						rows={8}
						value={state.description || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.description = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Observações">
					<TextArea
						rows={4}
						value={state.notes || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.notes = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Status"}>
					<Select
						value={state?.status || ""}
						onChange={(e) => {
							setState((d) => {
								d.status = e;
							});
						}}
					>
						<Select.Option value="pending">Aguardando</Select.Option>
						<Select.Option value="done">Finalizado</Select.Option>
						<Select.Option value="cancelled">Cancelado</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Arquivo"}>
					<Upload
						maxCount={1}
						accept={".pdf,.gif,.jpg,.jpeg,.png"}
						beforeUpload={() => false}
						onChange={(e) => {
							setState((d) => {
								d.file = e.file;
							});
						}}
					>
						<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
					</Upload>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ServiceModal;
