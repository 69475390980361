import React, { useEffect, useRef } from "react";
import * as Queries from "./Queries";
import { useQuery, useMutation, gql } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import MaskPrice from "functions/mask/price";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Badge,
	Button,
	Form,
	Select,
	Input,
	Popconfirm,
	Tooltip,
	Dropdown,
	Menu,
	notification,
} from "antd";
import { SearchOutlined, FileOutlined } from "@ant-design/icons";
import MaskedInputCPFCNPJ from "components/InputCPFCNPJ";
import Swal from "sweetalert2";

import {
	EditOutlined,
	DownOutlined,
	MessageOutlined,
	DeleteOutlined,
	FileTextOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useImmer } from "use-immer";

import UserSearch from "components/UserSearch";
import BusinessSearch from "components/BusinessSearch";

import Modal from "./Modal";
import ModalCreate from "./ModalCreate";
import dayjs from "dayjs";
import InvoiceConversation from "./InvoiceConversation";

const { Content } = Layout;
const { Title } = Typography;

function DAS() {
	const mounted = useRef(false);

	const [conversation, setConversation] = useImmer({
		visible: false,
	});

	const [deleteId, setDeleteId] = useImmer(null);
	const [modal, setModal] = useImmer({
		visible: false,
	});
	const [create, setCreate] = useImmer({
		visible: false,
	});

	const [filters, setFilters] = useImmer({
		status: "",
	});

	const { data, loading, error, refetch } = useQuery(Queries.INVOICES, {
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
	});

	const [remove, { loading: loadingDelete }] = useMutation(
		gql`
			mutation InvoiceRemove($id: String!) {
				InvoiceRemove(id: $id)
			}
		`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const removeInvoice = async (id) => {
		Swal.fire({
			title: "Remover Nota Fiscal",
			text: "Tem certeza que deseja remover? Esta ação não pode ser desfeita.",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await remove({
					variables: {
						id,
					},
				});

				if (res?.data?.InvoiceRemove) {
					refetch();
					setDeleteId(null);
					notification.success({
						message: "Nota fiscal removida com sucesso",
					});
				} else {
					setDeleteId(null);
					notification.error({
						message: "Não foi possível remover a nota no momento.",
					});
				}
			}
		});
	};

	const columns = [
		{
			title: "",
			dataIndex: "status",
			key: "status",
			width: 10,
			render: (value) => {
				if (value === "pending") return <Badge color="gold" />;
				if (value === "done") return <Badge color="green" />;
				if (value === "cancelled") return <Badge color="volcano" />;
			},
		},
		{
			title: "Data",
			dataIndex: "createdAt",
			key: "createdAt",
			width: 160,
			render: (value, row) => {
				return dayjs(value).format("DD/MM/YYYY HH:mm") || "--";
			},
		},
		{
			title: "CPF/CNPJ",
			dataIndex: "cpfcnpj",
			key: "cpfcnpj",
			width: 180,
			render: (value, row) => {
				return value || "--";
			},
		},
		{
			title: "Tomador",
			dataIndex: "taker",
			key: "taker",
			render: (value, row) => {
				return value || "--";
			},
		},
		{
			title: "Empresa",
			dataIndex: "business_name",
			key: "business_name",
			render: (value, row) => {
				return <a href={`user/${row.user}`}>{row.business_name}</a>;
			},
		},
		// {
		// 	title: "Emp. Parceiro",
		// 	dataIndex: "partner_company",
		// 	key: "partner_company",
		// 	render: (value, row) => {
		// 		console.log(row);
		// 		return row?.partner_company?.name || "-";
		// 	},
		// },
		{
			title: "Valor",
			dataIndex: "amount",
			key: "amount",
			width: 140,
			render: (value, row) => {
				return value
					? value.toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL",
					  })
					: "--";
			},
		},
		{
			title: "Ações",
			dataIndex: "actions",
			key: "actions",
			width: 80,
			render: (value, row) => {
				const menu = (
					<Menu>
						<Menu.Item
							icon={<EditOutlined />}
							onClick={() => {
								setCreate({
									visible: true,
									data: row,
								});
							}}
						>
							Editar
						</Menu.Item>
						<Menu.Item
							onClick={() => {
								setModal({
									visible: true,
									data: row,
								});
							}}
							icon={<FileTextOutlined />}
						>
							Informações
						</Menu.Item>
						<Menu.Item
							icon={<DeleteOutlined />}
							danger
							onClick={() => {
								removeInvoice(row._id);
							}}
						>
							Remover
						</Menu.Item>
					</Menu>
				);

				return (
					<div style={{ display: "flex" }}>
						<Dropdown
							placement="bottomRight"
							overlay={menu}
							trigger={["click"]}
							arrow
						>
							<Button>
								Ações <DownOutlined />
							</Button>
						</Dropdown>
					</div>
				);
			},
		},
		{
			title: "Chat",
			dataIndex: "",
			key: "x",
			width: 80,
			render: (value, row) => {

				return (
					<div>
						<Tooltip title="Mensagens">
							{row?.unread ? (
								<Badge dot>
									<Button
										shape="circle"
										icon={<MessageOutlined />}
										onClick={() => {
											setConversation({
												visible: true,
												data: row,
											});
										}}
									/>
								</Badge>
							) : (
								<Button
									shape="circle"
									icon={<MessageOutlined />}
									onClick={() => {
										setConversation({
											visible: true,
											data: row,
										});
									}}
								/>
							)}
						</Tooltip>
					</div>
				);
			},
		},
	];

	const onRefetch = async () => {
		refetch({
			filters,
		});
		window.refetchInvoicesBadge();
	};

	const filterAction = async () => {
		refetch({
			filters,
		});
	};

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Notas Fiscais</Title>
					<Button
						type="primary"
						onClick={() => {
							setCreate({ visible: true });
						}}
					>
						Criar
					</Button>
				</div>

				<div style={{ marginBottom: 10 }}>
					<Form layout="vertical">
						<Row gutter={20}>
							<Col span="6">
								<Form.Item label={"Usuário"}>
									<UserSearch
										onChange={(e) => {
											setFilters((d) => {
												d.user = e;
											});
										}}
										additionalProps={{
											allowClear: true,
										}}
									/>
								</Form.Item>
							</Col>
							<Col span="6">
								<Form.Item label={"Empresa"}>
									<BusinessSearch
										onChange={(e) => {
											setFilters((d) => {
												d.business_id = e;
											});
										}}
										additionalProps={{
											allowClear: true,
										}}
									/>
								</Form.Item>
							</Col>
							<Col span="6">
								<Form.Item label={"CPF/CNPJ"}>
									<MaskedInputCPFCNPJ
										value={filters?.cpfcnpj || null}
										onChange={(e) => {
											const { value } = e.target;
											setFilters((d) => {
												d.cpfcnpj = value;
											});
										}}
										allowClear
									/>
								</Form.Item>
							</Col>
							<Col flex="auto">
								<Form.Item label={"Status"}>
									<Select
										defaultValue={""}
										onChange={(e) => {
											setFilters((d) => {
												d.status = e;
											});
										}}
									>
										<Select.Option value="">Todos</Select.Option>
										<Select.Option value="pending">Aguardando</Select.Option>
										<Select.Option value="done">Finalizado</Select.Option>
										<Select.Option value="cancelled">Cancelado</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col>
								<Button onClick={filterAction} style={{ marginTop: 30 }}>
									Filtrar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>

				<Modal
					{...modal}
					close={() => {
						setModal({ visible: false });
					}}
					refetch={onRefetch}
				/>

				<ModalCreate
					{...create}
					close={() => {
						setCreate({ visible: false });
					}}
					refetch={onRefetch}
				/>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table
								rowKey="_id"
								dataSource={data?.Invoices || []}
								columns={columns}
							/>
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>

				<InvoiceConversation
					{...conversation}
					refetchTop={refetch}
					close={() => {
						setConversation({
							visible: false,
						});
					}}
				/>
			</Content>
		</Layout>
	);
}

export default DAS;
