import React, { useState, useEffect } from "react";
import * as Queries from "./Queries";
import { useQuery, useMutation } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import MaskPrice from "functions/mask/price";
import _ from "lodash";
import MaskedInput from "antd-mask-input";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Card,
	Form,
	Input,
	Select,
	Divider,
	DatePicker,
	Upload,
	notification,
} from "antd";
import Estados from "functions/states";
import moment from "moment";
import Swal from "sweetalert2";
import isEmail from "validator/lib/isEmail";
import { statuses } from "./Misc";
import { useDebounce, useDebounceCallback } from "@react-hook/debounce";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useImmer } from "use-immer";

import PartnerSearch from "components/PartnerSearch";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

function User(props) {
	const [saveAction] = useMutation(Queries.CREATE_USER);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useImmer({
		info: {
			status: "1",
		},
		user_documents: {},
		business: {
		},
		address: {},
		contract: {},
		documents: {},
	});

	const [cepLoading, setCepLoading] = useState(false);
	const [cep, setCep] = useDebounce(null, 500);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		try {
			// Basic Info

			if (!getValue("info.name"))
				return ErrorDialog("Por favor, informe um nome válido.");

			if (!getValue("info.status"))
				return ErrorDialog("Por favor, selecione um status.");
			// User documents
			if (!getValue("business.company_name"))
				return ErrorDialog("Por favor, informe a razão social.");

			let obj = JSON.parse(JSON.stringify(state));

			obj.info.status = parseInt(obj.info.status);

			obj.documents = state.documents;

			setLoading(true);

			const { data, error } = await saveAction({
				variables: {
					input: obj,
				},
			});

			setLoading(false);

			if (error || !data.CreateUser.result) {
				let errorMsg =
					data.CreateUser.error ||
					"Não foi possível criar o usuário no momento.";
				return ErrorDialog(errorMsg);
			} else {
				notification.success({
					message: "Usuário criado",
					description: "Usuário criado com sucesso",
				});
				props.history.push("/users");
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		async function getCep(cep) {
			let code = cep.replace(/\D/g, "");

			if (code.length === `00000000`.length) {
				setCepLoading(true);
				let response = await axios.get(
					`https://viacep.com.br/ws/${code}/json/`
				);
				setCepLoading(false);
				if (response?.data) {
					setState((draft) => {
						draft.address.street = response.data.logradouro;
						draft.address.neighborhood = response.data.bairro;
						draft.address.city = response.data.localidade;
						draft.address.state = response.data.uf;
					});
				} else {
					return ErrorDialog("Não foi possível carregar o CEP");
				}
			}
		}
		if (cep) getCep(cep);
	}, [cep]);

	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Novo Usuário</Title>
				</div>

				<Form layout="vertical">
					<Title level={3}>Informações Básicas</Title>

					<Row gutter={20}>
						<Col span={16}>
							<Form.Item name="name" label="Nome">
								<Input
									defaultValue={state?.info?.name}
									onChange={(e) => {
										setState((draft) => {
											draft.info.name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="phone" label="Fone">
								<MaskedInput
									mask="(11) 11111-1111"
									defaultValue={state?.info?.phone}
									onChange={(e) => {
										setState((draft) => {
											draft.info.phone = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={16}>
							<Form.Item name="email" label="E-mail">
								<Input
									defaultValue={state?.info?.email}
									onChange={(e) => {
										setState((draft) => {
											draft.info.email = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="cpf" label="CPF">
								<MaskedInput
									mask="111.111.111-11"
									defaultValue={state?.info?.cpf}
									onChange={(e) => {
										setState((draft) => {
											draft.info.cpf = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Parceiro">
								<PartnerSearch
									onChange={(e) => {
										setState((draft) => {
											draft.info.partner_id = e;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Senha gov.br">
								<Input
									defaultValue={state?.info?.gov_br_password || ""}
									onChange={(e) => {
										const { value } = e.target;
										setState((draft) => {
											draft.info.gov_br_password = value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={4}>
							<Form.Item label="Status">
								<Select
									defaultValue={"1"}
									onChange={(e) => {
										setState((draft) => {
											draft.info.status = e;
										});
									}}
								> 
									<Select.Option value="0">Expirado</Select.Option>
									<Select.Option value="1">Ativo</Select.Option>
									<Select.Option value="2">
										Não cadastrou/requisitou empresa
									</Select.Option>
									<Select.Option value="3">Aguardando Abertura / Doc. Pendente</Select.Option>
									<Select.Option value="4">Inativo</Select.Option>
									<Select.Option value="5">Cancelado</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={4}>
							<Form.Item label="Plano">
								<Select
									onChange={(e) => {
										setState((draft) => {
											draft.info.plan = e;
										});
									}}
								>
									<Select.Option value="plano_super_boleto">
										SUPER Boleto
									</Select.Option>
									<Select.Option value="plano_top_boleto">
										TOP Boleto
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item label="Observações Gerais">
								<TextArea
									rows={4}
									onChange={(e) => {
										setState((draft) => {
											draft.info.notes = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Divider />
					<Title level={3}>Documentos do Usuário</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item name="mother_name" label="Nome da Mãe">
								<Input
									defaultValue={state?.user_documents?.mother_name}
									onChange={(e) => {
										setState((draft) => {
											draft.user_documents.mother_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="rg" label="RG">
								<Input
									defaultValue={state?.user_documents?.rg}
									onChange={(e) => {
										setState((draft) => {
											draft.user_documents.rg = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="birth_date" label="Data de Nascimento">
								<MaskedInput
									mask={"11/11/1111"}
									defaultValue={state?.user_documents?.birth_date}
									onChange={(e) => {
										setState((draft) => {
											draft.user_documents.birth_date = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="voter_title" label="Título de Eleitor">
								<Input
									defaultValue={state?.user_documents?.voter_title}
									onChange={(e) => {
										setState((draft) => {
											draft.user_documents.voter_title = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name="tax_code" label="Número Imposto de Renda">
								<Input
									defaultValue={state?.user_documents?.tax_code}
									onChange={(e) => {
										setState((draft) => {
											draft.user_documents.tax_code = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Divider />
					<Title level={3}>Empresa</Title>

					<Row gutter={20}>
						<Col span={16}>
							<Form.Item name="razao" label="Razão Social">
								<Input
									defaultValue={state?.business?.company_name}
									onChange={(e) => {
										setState((draft) => {
											draft.business.company_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="cnpj" label="CNPJ">
								<MaskedInput
									mask={"11.111.111/1111-11"}
									defaultValue={state?.business?.cnpj}
									onChange={(e) => {
										setState((draft) => {
											draft.business.cnpj = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="trade_name" label="Nome Fantasia">
								<Input
									defaultValue={state?.business?.trade_name}
									onChange={(e) => {
										setState((draft) => {
											draft.business.trade_name = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="business_email" label="E-mail da Empresa">
								<Input
									defaultValue={state?.business?.email}
									onChange={(e) => {
										setState((draft) => {
											draft.business.email = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="access_code" label="Código de Acesso Nacional">
								<Input
									defaultValue={state?.business?.access_code}
									onChange={(e) => {
										setState((draft) => {
											draft.business.access_code = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="municipal_registration"
								label="Registro Municipal"
							>
								<Input
									defaultValue={state?.business?.municipal_registration}
									onChange={(e) => {
										setState((draft) => {
											draft.business.municipal_registration = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

					</Row>

					<Divider />

					<Title level={3}>Endereço Comercial</Title>

					<Row gutter={20}>
						<Col span={6}>
							<Form.Item label="CEP">
								<MaskedInput
									mask={"11111-111"}
									loading={cepLoading}
									defaultValue={state?.address?.zip_code}
									onChange={(e) => {
										setState((draft) => {
											draft.address.zip_code = e.target.value;
										});
										setCep(e.target.value);
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item label="Endereço">
								<Input
									value={state?.address?.street}
									onChange={(e) => {
										setState((draft) => {
											draft.address.street = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label="Número">
								<Input
									value={state?.address?.number}
									onChange={(e) => {
										setState((draft) => {
											draft.address.number = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label="Complemento">
								<Input
									value={state?.address?.complement}
									onChange={(e) => {
										setState((draft) => {
											draft.address.complement = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Bairro">
								<Input
									value={state?.address?.neighborhood}
									onChange={(e) => {
										setState((draft) => {
											draft.address.neighborhood = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item label="Cidade">
								<Input
									value={state?.address?.city}
									onChange={(e) => {
										setState((draft) => {
											draft.address.city = e.target.value;
										});
									}}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item label="Estado">
								<Select
									value={state?.address?.state}
									onChange={(e) => {
										setState((draft) => {
											draft.address.state = e;
										});
									}}
								>
									{Estados("all").map((item, index) => {
										return (
											<Select.Option key={`state_${index}`} value={item.value}>
												{item.text}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<Divider />

					<Title level={3}>Contrato</Title>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item name="sign_date" label="Data de Assinatura">
								<Input
									disabled={true}
									defaultValue={moment().format("DD/MM/YYYY")}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="responsibility_start"
								label="Data de Início de Responsabilidade"
							>
								<DatePicker
									format={"MM/YYYY"}
									picker="month"
									onChange={(e) => {
										setState((draft) => {
											draft.contract.responsibility_start = e.toDate();
										});
									}}
									style={{
										width: "100%",
									}}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Divider />

					<Title level={3}>Documentos</Title>

					<Row gutter={20}>
						<Col span={24}>
							<Row gutter={30}>
								<Col span={6}>
									<Form.Item name={"alvara"} label={"Alvará"}>
										<Upload
											name={"alvara"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.alvara = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"cartao_cnpj"} label={"Cartão CNPJ"}>
										<Upload
											name={"cartao_cnpj"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.cartao_cnpj = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"certificado"} label={"Certificado"}>
										<Upload
											name={"certificado"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.certificado = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"rg"} label={"RG"}>
										<Upload
											name={"rg"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.rg = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"cpf"} label={"CPF"}>
										<Upload
											name={"cpf"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.cpf = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"cnh"} label={"CNH"}>
										<Upload
											name={"cnh"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.cnh = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										name={"titulo_eleitor"}
										label={"Título de Eleitor"}
									>
										<Upload
											name={"titulo_eleitor"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.titulo_eleitor = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item name={"iptu"} label={"IPTU"}>
										<Upload
											name={"iptu"}
											beforeUpload={() => false}
											onChange={(e) => {
												const { file } = e;
												setState((draft) => {
													draft.documents.iptu = file;
												});
											}}
										>
											<Button icon={<UploadOutlined />}>Enviar</Button>
										</Upload>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>

					<Divider />

					<Col span={24}>
						<Button
							type="primary"
							style={{ float: "right" }}
							loading={loading}
							onClick={save}
						>
							Salvar
						</Button>
					</Col>
				</Form>
			</Content>
		</Layout>
	);
}

export default User;
