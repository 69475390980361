import React, { useState, useEffect, useReducer } from "react";
import * as Queries from "./Queries";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import {
	Col,
	Row,
	Layout,
	Table,
	Typography,
	Button,
	Select,
	Form,
	Input,
	Popconfirm,
	notification,
} from "antd";

import { useImmer } from "use-immer";
import { filter } from "lodash";

const { Content } = Layout;
const { Title } = Typography;

function Users(props) {
	const [filters, setFilters] = useImmer({
		status: ''
	});
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const [removePartnerAction] = useMutation(Queries.PARTNER_REMOVE);

	const [load, { loading, data, error, refetch }] = useLazyQuery(
		Queries.PARTNERS,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		load();
	}, []);

	const removePartner = async (row) => {
		let res = await removePartnerAction({
			variables: {
				id: row._id,
			},
		});
		if (res.data.PartnerRemove) {
			notification.success({
				message: "Parceiro removido com sucesso!",
			});
			refetch();
		} else {
			notification.error({
				message: "Erro ao remover parceiro",
				description: "Por favor, tente novamente mais tarde.",
			});
		}
	};

	const columns = [
		{
			title: "Nome",
			dataIndex: "company_name",
			key: "company_name",
		},
		{
			title: "CNPJ",
			dataIndex: "cnpj",
			key: "cnpj",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Telefone",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Créditos",
			dataIndex: "balance",
			key: "balance",
		},
		{
			title: "Plano",
			dataIndex: "plan_id",
			key: "plan_id",
			render: (plan_id) => {
				switch (plan_id) {
					case 1:
						return "Mensal";
						break;
					case 2:
						return "Pré-pago";
						break;
				}
			},
		},
		{
			title: "Ações",
			dataIndex: "",
			key: "x",
			width: 200,
			render: (value, row) => {
				return (
					<>
						<Button
							type="link"
							onClick={() => {
								props.history.push(`./partner/${value._id}`);
							}}
						>
							Editar
						</Button>
						<Popconfirm
							title="Deseja remover este parceiro?"
							onConfirm={() => {
								removePartner(row);
							}}
							onCancel={null}
							okText="Sim"
							cancelText="Não"
						>
							<Button type="link">Remover</Button>
						</Popconfirm>
					</>
				);
			},
		},
	];

	const getList = () => {


		if (data?.Partners?.length) {
			return data.Partners.filter((item) => {
				if (filters?.name !== "" || filters?.status !== "") {

					let obj = item;
					
					if (
						filters?.name &&
						!item?.company_name
							?.toLowerCase()
							.includes(filters.name.toLowerCase())
					) {
						obj = null;
					}
					if (
						filters?.email &&
						!item?.email?.toLowerCase().includes(filters.email.toLowerCase())
					) {
						obj = null;
					}
					if (filters?.status !== '' && item.status !== filters.status) {
						obj = null;
					}

					return obj;
				} else {
					return item;
				}
			});
		} else {
			return [];
		}
	};


	return (
		<Layout className={"page-wrapper"}>
			<Content className="site-layout-background">
				<div className="page-title">
					<Title>Parceiros</Title>
					<Button
						type="primary"
						onClick={() => {
							props.history.push("/partner/new");
						}}
					>
						Novo Parceiro
					</Button>
				</div>
				<div style={{ marginBottom: "10px" }}>
					<Row gutter={20}>
						<Col flex="auto">
							<Form.Item>
								<Input
									placeholder="Nome"
									onChange={(e) => {
										const { value } = e.target;
										setFilters((d) => {
											d.name = value !== "" ? value : null;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col flex="auto">
							<Form.Item>
								<Input
									placeholder="E-mail"
									onChange={(e) => {
										const { value } = e.target;
										setFilters((d) => {
											d.email = value !== "" ? value : null;
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Status">
								<Select
									defaultValue={""}
									onChange={(value) => {
										setFilters((draft) => {
											draft.status = value;
										});
									}}
								>
									<Select.Option value={""}>Qualquer</Select.Option>
									<Select.Option value={0}>Inativo</Select.Option>
									<Select.Option value={1}>Ativo</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</div>

				<div className="dashboard">
					{loading ? <Loading /> : null}

					{!loading && !error && data ? (
						<div>
							<Table rowKey="_id" dataSource={getList()} columns={columns} />
						</div>
					) : null}

					{!loading && error ? <Error refetch={() => refetch()} /> : null}
				</div>
			</Content>
		</Layout>
	);
}

export default Users;
