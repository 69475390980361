import React, { useState, useEffect, useRef } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation } from "@apollo/client";

import Loading from "components/Loading/Page";
import Error from "components/Error/Page";
import _ from "lodash";

import {
	Col,
	Row,
	Spin,
	List,
	Button,
	Card,
	Form,
	Radio,
	Upload,
	Popconfirm,
	notification,
} from "antd";
import { DynamicDocumentView, Guides } from "./DocumentsComponents";

import {
	UploadOutlined,
	FilePdfOutlined,
	FileImageOutlined,
	DeleteOutlined,
} from "@ant-design/icons";
const { Dragger } = Upload;

function Document({ id, name, label, data, business_id, refetch }) {
	const didMount = useRef(false);
	const [upload] = useMutation(Queries.DOCUMENT_UPLOAD);
	const [deleteDoc] = useMutation(Queries.DOCUMENT_DELETE);
	const [state, setState] = useState(null);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (data) setState(data);
		didMount.current = true;
	}, []);

	useEffect(() => {
		if (didMount.current) {
			setState(data);
		}
	}, [data]);

	const uploadFile = async (e) => {
		const { data, error } = await upload({
			variables: {
				input: {
					name: name,
					file: e.file,
				},
				uid: id,
				business_id,
			},
		});

		console.log(data);

		if (error) {
			notification.error({
				message: "Erro",
				description:
					"Não foi possível enviar o arquivo. Por favor, tente novamente mais tarde.",
			});
		} else {
			setState(data.BusinessDocument);
			setList([]);
		}
	};

	const handleChange = ({ fileList }) => {
		setList(fileList);
	};

	const open = () => {
		window.open(state.path);
	};

	const removeFile = async () => {
		let res = await deleteDoc({
			variables: {
				document_id: state._id,
				document_type: name,
				uid: id,
				business_id,
			},
		});

		if (res?.data?.BusinessDocumentDelete) {
			refetch();
			setState(null);
		} else {
			notification.error({
				message: "Erro",
				description: "Não foi possível remover o documento no momento.",
			});
		}

		console.log(res);
	};

	return (
		<Form.Item name={name}>
			<Card
				size="small"
				type="inner"
				title={label}
				extra={
					state ? (
						<>
							<Button type="primary" onClick={open}>
								Ver
							</Button>
							<Popconfirm
								title="Tem certeza que deseja remover?"
								okText="Sim"
								cancelText="Não"
								placement="topRight"
								onConfirm={() => {
									removeFile();
								}}
							>
								<Button style={{ marginLeft: 10 }}>
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						</>
					) : null
				}
			>
				<Upload
					name={name}
					customRequest={uploadFile}
					onChange={handleChange}
					fileList={list}
				>
					<Button icon={<UploadOutlined />}>Enviar</Button>
				</Upload>
			</Card>
		</Form.Item>
	);
}

//
// Main View
//

function Documents({ id, business_id }) {
	const dynamicDocuments = [
		{ key: "social_contract", label: "Contrato Social" },
		{ key: "annual_balance", label: "Balanço Anual" },
		{ key: "dre", label: "DRE" },
		{ key: "certificates", label: "Certidões" },
		{ key: "statements", label: "Declarações" },
		{ key: "other", label: "Outros" },
	];

	const [state, setState] = useState(null);
	const [activeIndex, setActiveIndex] = useState(dynamicDocuments[0].key);

	const { loading, data, error, refetch } = useQuery(Queries.DOCUMENTS, {
		fetchPolicy: "no-cache",
		variables: {
			id: id,
			business_id,
		},
	});

	useEffect(() => {
		if (data) {
			setState(data?.Business?.documents ? data?.Business?.documents : {});
		}
	}, [data]);

	const getValue = (path) => {
		return _.get(state, path);
	};

	const setValue = (path, value) => {
		setState((state) => {
			_.set(state, path, value);
			return state;
		});
	};


	return (
		<Row gutter={20}>
			<Col span={24}>
				<Card title="Documentos">
					{loading ? <Loading /> : null}

					{!loading && error ? <Error refetch={refetch} /> : null}

					{state && !loading && !error ? (
						<Form layout="vertical">
							<Row gutter={20}>
								<Col span={8}>
									<Document
										label={"Alvará"}
										data={state.alvara}
										name={"alvara"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"Cartão CNPJ"}
										data={state.cartao_cnpj}
										name={"cartao_cnpj"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"Certificado"}
										data={state.certificado}
										name={"certificado"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"RG"}
										data={state.rg}
										name={"rg"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"CPF"}
										data={state.cpf}
										name={"cpf"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"CNH"}
										data={state.cnh}
										name={"cnh"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"Título de Eleitor"}
										data={state.titulo_eleitor}
										name={"titulo_eleitor"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>

								<Col span={8}>
									<Document
										label={"IPTU"}
										data={state.iptu}
										name={"iptu"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>
								<Col span={8}>
									<Document
										label={"Contrato Social"}
										data={state.social_contract}
										name={"social_contract"}
										id={id}
										business_id={business_id}
										refetch={refetch}
									/>
								</Col>
							</Row>
						</Form>
					) : null}
				</Card>
			</Col>
			<Col span={24}>
				<Card title="Outros Documentos">
					<Radio.Group
						className="full-tabs"
						value={activeIndex}
						onChange={(e) => {
							setActiveIndex(e.target.value);
						}}
						style={{ marginBottom: 16 }}
					>
						{dynamicDocuments.map((item) => {
							return <Radio.Button value={item.key}>{item.label}</Radio.Button>;
						})}
					</Radio.Group>

					{dynamicDocuments.map((item) => {
						if (item.key === activeIndex) {
							return (
								<DynamicDocumentView
									id={id}
									key={item.key}
									documentType={item.key}
									business_id={business_id}
								/>
							);
						}
						return null;
					})}
				</Card>
			</Col>
			<Col span={24}>
					<Guides
						id={id}
						key={"guides"}
						documentType={"guides"}
						business_id={business_id}
					/>
			</Col>
		</Row>
	);
}

export default Documents;
