import { gql } from '@apollo/client';

export const REFERS = gql`
query {
  Refers {
    name
    email
    phone
    message
    referred_by
  }
}
`;

