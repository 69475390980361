import React, { useState, useEffect, useRef } from "react";
import * as Queries from "../Queries";
import { useQuery, useMutation, gql } from "@apollo/client";

import {
	Spin,
	List,
	Button,
	Upload,
	Badge,
	Popconfirm,
	notification,
	Table,
	Modal,
	Form,
	Tooltip,
	DatePicker,
	Card,
	Select,
} from "antd";
import MaskPrice from "functions/mask/price";
import {
	UploadOutlined,
	FilePdfOutlined,
	FileImageOutlined,
	FileOutlined,
	DeleteOutlined,
	EditOutlined,
	CheckCircleOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useImmer } from "use-immer";
import InputNumber from "components/InputNumber";
import Swal from "sweetalert2";

const { Dragger } = Upload;

export function DynamicDocumentView({ id: uid, documentType, business_id }) {
	const [upload, { loading: loadingUpload }] = useMutation(
		Queries.DOCUMENT_UPLOAD_DYNAMIC
	);
	const [deleteFile, { loading: deleteLoading }] = useMutation(
		Queries.DOCUMENT_DELETE_DYNAMIC
	);

	const { data, loading, refetch, error } = useQuery(
		Queries.DOCUMENTS_DYNAMIC,
		{
			variables: {
				documentType,
				uid,
				business_id,
			},
			fetchPolicy: "no-cache",
		}
	);

	const [list, setList] = useState([]);

	const uploadFiles = async () => {
		if (!list.length) return alert("Selecione um ou mais arquivos");

		let payload = {
			uid,
			business_id,
			action: "upload",
			input: list.map((file) => {
				return {
					file: file.originFileObj,
					documentType,
				};
			}),
		};
		
		console.log("payload", payload);

		let res = await upload({
			variables: payload,
		});

		if (res?.data?.BusinessDocumentDynamicUpload) {
			setList([]);
			refetch({
				fetchPolicy: "no-cache",
			});
		} else {
			notification.error({
				message: "Erro ao enviar arquivos",
				description:
					"Um ou mais arquivos não puderam ser enviados. Tente novamente.",
			});
		}
	};

	const open = (path) => {
		window.open(path);
	};

	const renderIcon = (mime) => {
		if (mime === "application/pdf") {
			return <FilePdfOutlined />;
		}
		return <FileImageOutlined />;
	};

	const removeFile = async (item) => {
		let res = await deleteFile({
			variables: {
				document_id: item._id,
				documentType,
				business_id,
				uid,
			},
		});

		if (res?.data?.BusinessDocumentDynamicDelete) {
			refetch({
				fetchPolicy: "no-cache",
			});
		} else {
			notification.error({
				message: "Erro ao enviar arquivos",
				description:
					"Um ou mais arquivos não puderam ser deletados. Tente novamente.",
			});
		}
	};

	return (
		<div>
			{loading && (
				<div style={{ textAlignLast: "center", padding: "3rem 0" }}>
					<Spin />
				</div>
			)}

			{!loading && !error && data?.BusinessDocumentsDynamic?.length ? (
				<List loading={deleteLoading}>
					{data?.BusinessDocumentsDynamic?.length
						? data.BusinessDocumentsDynamic.map((item) => {
								return (
									<List.Item
										actions={[
											<Popconfirm
												title="Tem certeza que deseja remover?"
												okText="Sim"
												cancelText="Não"
												placement="topRight"
												onConfirm={() => {
													removeFile(item);
												}}
											>
												<Button>
													<DeleteOutlined />
												</Button>
											</Popconfirm>,
										]}
									>
										<div>{renderIcon(item.mimetype)}</div>
										<div style={{ flex: 1, marginLeft: 10 }}>
											<a href={item.key} target="_blank">
												{item.name}
											</a>
										</div>
									</List.Item>
								);
						  })
						: null}
				</List>
			) : null}

			<div style={{ padding: "0rem 0" }}>
				<Dragger
					multiple
					style={{ padding: "1rem" }}
					onChange={(e) => {
						setList(e.fileList);
					}}
					beforeUpload={() => false}
					fileList={list}
					accept="application/pdf,image/jpeg,image/png,application/zip,application/x-zip,application/x-zip-compressed"
				>
					<p className="ant-upload-drag-icon">
						<UploadOutlined />
					</p>
					<p className="ant-upload-text">
						Clique ou arraste arquivos para fazer o upload
					</p>
					<p className="ant-upload-hint">
						Arquivos suportados: .pdf, .png, .jpeg, .jpg, .zip
					</p>
				</Dragger>
			</div>

			{list.length ? (
				<Button
					block
					size="large"
					style={{ marginTop: "1rem" }}
					type="primary"
					icon={<UploadOutlined />}
					onClick={uploadFiles}
					loading={loadingUpload}
					disabled={loadingUpload}
				>
					Enviar
				</Button>
			) : null}
		</div>
	);
}

function GuidesModal({ visible, close, data, business_id, uid, refetch }) {
	const [state, setState] = useImmer({});
	const [list, setList] = useImmer([]);

	const [saveAction, { loading }] = useMutation(gql`
		mutation BusinessGuide($input: BusinessGuideInput!, $uid: String!, $business_id: String!, $document_id: String) {
			BusinessGuide(input: $input, business_id: $business_id, uid: $uid, document_id: $document_id)
		}
	`);

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			type: "error",
			confirmButtonText: "OK",
		});
	};

	const save = async () => {
		if (!data && !state?.file) {
			return ErrorDialog("Por favor, selecione um arquivo.");
		}

		if (!state?.type) return ErrorDialog("Por favor, informe um tipo.");
		if (!state?.dueDate)
			return ErrorDialog("Por favor, selecione uma data de vencimento.");
		if (!state?.total) return ErrorDialog("Por favor, informe o valor total.");
		if (!state?.status) return ErrorDialog("Por favor, informe um status.");

		let input = { ...state };
		input.dueDate = input.dueDate.toISOString();
		if (input.paymentDate) input.paymentDate = input.paymentDate.toISOString();

		try {
			let res = await saveAction({
				variables: {
					input,
					uid,
					business_id,
					document_id: data?._id ? data._id : null,
				},
			});

			if (res?.data?.BusinessGuide) {
				refetch();
				close();
				setState({});
				setList([]);
			} else {
				ErrorDialog(
					"Não foi possível salvar no momento. Tente novamente mais tarde."
				);
			}
		} catch (error) {
			console.log(error);
			ErrorDialog(
				"Não foi possível salvar no momento. Tente novamente mais tarde."
			);
		}
	};

	const onClose = () => {
		close();
		setState({});
		setList([]);
	};

	useEffect(() => {
		if (visible) {
			if (data) {
				let {
					key,
					_id,
					createdAt,
					name,
					mimetype,
					receiptKey,
					readAt,
					...obj
				} = data;

				console.log(data);

				if (obj.paymentDate) obj.paymentDate = moment(obj.paymentDate);
				if (obj.dueDate) obj.dueDate = moment(obj.dueDate);
				setState(obj);
			} else {
				setState((d) => {
					d.status = "pending";
				});
			}
		}
	}, [visible, data]);


	return (
		<Modal
			title="Sobre a Guia"
			visible={visible}
			onOk={() => {
				save();
			}}
			onCancel={() => {
				onClose();
			}}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={save}
				>
					Enviar
				</Button>,
			]}
		>
			<Form layout="vertical">
				<Form.Item label="Arquivo">
					<div style={{ display: "flex" }}>
						<Upload
							beforeUpload={() => false}
							onChange={(e) => {
								const { file } = e;
								setState((draft) => {
									draft.file = file;
								});
								setList([file]);
							}}
							fileList={list}
						>
							<Button icon={<UploadOutlined />}>Enviar arquivo</Button>
						</Upload>

						{data?.key ? (
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								onClick={() => {
									window.open(data.key);
								}}
								icon={<FileOutlined />}
							>
								Ver arquivo
							</Button>
						) : null}
					</div>
				</Form.Item>

				{data?.receiptKey ? (
					<Form.Item label="Recibo">
						<div style={{ display: "flex" }}>
							<Button
								type="primary"
								onClick={() => {
									window.open(data.receiptKey);
								}}
								icon={<FileOutlined />}
							>
								Ver arquivo
							</Button>
						</div>
					</Form.Item>
				) : null}

				<Form.Item label="Tipo">
					<Select
						options={[
							{
								value: "DAS",
								label: "DAS",
							},
							{
								value: "ISS",
								label: "ISS",
							},
							{
								value: "PIS",
								label: "PIS",
							},
							{
								value: "COFINS",
								label: "COFINS",
							},
							{
								value: "IRPJ",
								label: "IRPJ",
							},
							{
								value: "CSLL",
								label: "CSLL",
							},
							{
								value: "INSS + IRRF (PRÓ-LABORE)",
								label: "INSS + IRRF (PRÓ-LABORE)",
							},
							{
								value: "RETENÇÃO CSRF",
								label: "RETENÇÃO CSRF",
							},
							{
								value: "RETENÇÃO IRRF",
								label: "RETENÇÃO IRRF",
							},
							{
								value: "RETENÇÃO INSS",
								label: "RETENÇÃO INSS",
							},
							{
								value: "RETENÇÃO ISS",
								label: "RETENÇÃO ISS",
							},
							{
								value: "TLI - TAXA DE LICENCIAMENTO",
								label: "TLI - TAXA DE LICENCIAMENTO",
							},
							{
								value: "TFE - TAXA DE FISCALIZAÇÃO",
								label: "TFE - TAXA DE FISCALIZAÇÃO",
							},
							{
								value: "TA - TAXA DE ALVARÁ",
								label: "TA - TAXA DE ALVARÁ",
							},
							{
								value: "TAXA JUNTA",
								label: "TAXA JUNTA",
							},
							{
								value: "TAXA BOMBEIRO",
								label: "TAXA BOMBEIRO",
							},
							{
								value: "TAXA DISPENSA DE LICENÇAS",
								label: "TAXA DISPENSA DE LICENÇAS",
							},
							{
								value: "RETENÇÕES SERV. TOMADOS",
								label: "RETENÇÕES SERV. TOMADOS",
							},
							{
								value: "PARCELAMENTO RFB",
								label: "PARCELAMENTO RFB",
							},
							{
								value: "PARCELAMENTO PREFEITURA",
								label: "PARCELAMENTO PREFEITURA",
							},
							{
								value: "OUTRAS TAXAS",
								label: "OUTRAS TAXAS",
							},
						]}
						onChange={(e) => {
							setState((d) => {
								d.type = e;
							});
						}}
						value={state?.type ?? null}
						placeholder="Selecione"
					/>
				</Form.Item>

				<Form.Item label="Data Vencimento">
					<DatePicker
						style={{ width: "100%" }}
						format={"DD/MM/YYYY"}
						value={state?.dueDate ? state.dueDate : null}
						onChange={(e) => {
							setState((d) => {
								d.dueDate = e;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Data Pagamento">
					<DatePicker
						style={{ width: "100%" }}
						format={"DD/MM/YYYY"}
						value={state?.paymentDate ? state.paymentDate : null}
						onChange={(e) => {
							setState((d) => {
								d.paymentDate = e;
							});
						}}
					/>
				</Form.Item>
				<Form.Item label="Total">
					<InputNumber
						onChange={(e) => {
							setState((d) => {
								d.total = e;
							});
						}}
						value={state?.total ? state.total : null}
					/>
				</Form.Item>
				<Form.Item label="Status">
					<Select
						onChange={(e) => {
							setState((d) => {
								d.status = e;
							});
						}}
						options={[
							{ value: "pending", label: "Pendente" },
							{ value: "paid", label: "Pago" },
							{ value: "cancelled", label: "Cancelado" },
						]}
						value={state?.status ?? null}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export function Guides({ id: uid, documentType, business_id }) {
	const [modal, setModal] = useImmer({
		visible: false,
	});

	const [deleteFile, { loading: deleteLoading }] = useMutation(
		Queries.DOCUMENT_DELETE_DYNAMIC
	);

	const { data, loading, refetch, error } = useQuery(
		Queries.DOCUMENTS_DYNAMIC,
		{
			variables: {
				documentType,
				uid,
				business_id,
			},
			fetchPolicy: "no-cache",
		}
	);

	const [list, setList] = useState([]);

	const removeFile = async (item) => {
		let res = await deleteFile({
			variables: {
				document_id: item._id,
				documentType,
				business_id,
				uid,
			},
		});

		if (res?.data?.BusinessDocumentDynamicDelete) {
			refetch({
				fetchPolicy: "no-cache",
			});
		} else {
			notification.error({
				message: "Erro ao enviar arquivos",
				description:
					"Um ou mais arquivos não puderam ser deletados. Tente novamente.",
			});
		}
	};

	return (
		<Card
			title="Guias"
			extra={
				<Button
					type="primary"
					onClick={() => {
						setModal({
							visible: true,
						});
					}}
				>
					<PlusOutlined />
				</Button>
			}
		>
			<div>
				{loading && (
					<div style={{ textAlignLast: "center", padding: "3rem 0" }}>
						<Spin />
					</div>
				)}

				{!loading && !error && data?.BusinessDocumentsDynamic?.length ? (
					<Table
						dataSource={data?.BusinessDocumentsDynamic}
						columns={[

							{
								title: "Tipo",
								dataIndex: "type",
								key: "type",
								render: (value) =>
									value ?? "-",
							},
							{
								title: "Data",
								dataIndex: "createdAt",
								key: "createdAt",
								render: (value) =>
									value ? dayjs(value).format("DD/MM/YYYY") : "-",
							},
							{
								title: "Data Vencimento",
								dataIndex: "dueDate",
								key: "dueDate",
								render: (value) =>
									value ? dayjs(value).format("DD/MM/YYYY") : "-",
							},
							{
								title: "Data Pagamento",
								dataIndex: "paymentDate",
								key: "paymentDate",
								render: (value) =>
									value ? dayjs(value).format("DD/MM/YYYY") : "-",
							},
							{
								title: "Status",
								dataIndex: "status",
								key: "status",
								render: (value) => {
									if (value === "pending")
										return <Badge color="gold" text="Pendente" />;
									if (value === "paid")
										return <Badge color="green" text="Pago" />;
									if (value === "cancelled")
										return <Badge color="volcano" text="Cancelado" />;
								},
							},
							{
								title: "Total",
								dataIndex: "total",
								key: "total",
								render: (value) => (value ? MaskPrice(value) : MaskPrice(0)),
							},
							{
								title: "Ações",
								dataIndex: "action",
								key: "action",
								width: 100,
								render: (value, row) => (
									<Button
										onClick={() => {
											setModal({
												visible: true,
												data: row,
											});
										}}
									>
										<EditOutlined />
									</Button>
								),
							},
							{
								title: "",
								dataIndex: "readAt",
								key: "readAt",
								render: (value) =>
									value ? (
										<div style={{}}>
											<Tooltip
												title={`Visualizado em ${dayjs(value).format("DD/MM/YYYY HH:mm:ss")}`}
											>
												<CheckCircleOutlined />
											</Tooltip>
										</div>
									) : (
										""
									),
							},
						]}
						pagination={{
							hideOnSinglePage: true,
						}}
					/>
				) : null}

				<GuidesModal
					{...modal}
					business_id={business_id}
					uid={uid}
					refetch={() => {
						refetch({
							fetchPolicy: "no-cache",
						});
					}}
					close={() => {
						setModal({
							visible: false,
						});
					}}
				/>
			</div>
		</Card>
	);
}
