import { gql } from '@apollo/client';

export const TICKETS = gql`
  query Tickets($input: TicketInput) {
    Tickets(input: $input) {
      _id
      name
      price
      description
      resolution_date
      status
      user {
        cpf
        name
        _id
      }
      partner {
        _id
        company_name
      }
      admin_id
      service {
        _id
        name
      }
      createdAt
      observation
      service_type
      business_name
      unread
    }
  }
`;

export const TICKET_SAVE = gql`
mutation Ticket($id: String!, $admin: String, $status: String) {
   Ticket(id:$id, admin: $admin, status: $status) {
    _id
  }
}
`;

export const TICKET_OBSERVATION = gql`
  mutation TicketObservation($id: String!, $observation: String) {
    TicketObservation(id: $id, observation: $observation) {
      _id
    }
  }
`;


export const TICKET_REMOVE = gql`
  mutation TicketRemove($id: String!) {
    TicketRemove(id: $id)
  }
`;

export const ADMINS = gql`
{
  Admins {
    _id
    name
  }
}
`;



export const SOCIAL_CONTRACT = gql`
  query Ticket_SocialContract($id: String!) {
    Ticket_SocialContract(id: $id) {
        address {
          street
          zip_code
          number
          complement
          neighborhood
          city
          state
          municipal_code
        }
        partners {
          address {
            street
            zip_code
            number
            complement
            neighborhood
            city
            state
            municipal_code
          }
          civil_status
          cpf
          managing_partner
          name
          participation
          pis
          pro_labore
        }
        partners_exclude
        baixa_cnpj
        email
        phone
        social_capital
    }
  }
`;

