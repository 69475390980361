import { gql } from '@apollo/client';

export const INVOICES = gql`
query Invoices($filters: InvoiceFilters) {
  Invoices(filters: $filters) {
    _id
    taker
    cpfcnpj
    amount
    description
    notes
    status
    finishedAt
    fileURL
    createdAt
    business_name
    business_id
    service_code
    service_code_text
    partner_company {
      name
    }
    user_files {
      name
      key
    }
    user
    user_name
  }
}
`;

export const INVOICE_SAVE = gql`
mutation Invoice($id: String!, $input: InvoiceInput!) {
  Invoice(id: $id, input: $input) {
    _id
  }
}
`;

export const INVOICE_REQUEST = gql`
mutation Invoice($id: String, $input: InvoiceInput!) {
	Invoice(id: $id, input: $input) {
    _id
    taker
    cpfcnpj
    amount
    description
    notes
    service_code
  }
}
`;
